//
// This file was generated by the Analytics code generator. (https://github.com/ynab/analytics)
// Do not hand-edit this file
//

export interface AnalyticsEventDefinition {
    name: string;
    properties: Array<AnalyticsProperties>;
}

export enum AnalyticsEvents {
    Accounts_AddLoanAccountDetails = "Accounts_AddLoanAccountDetails",
    Accounts_ClickedAddAccount = "Accounts_ClickedAddAccount",
    Accounts_ClickedAddActivityButton = "Accounts_ClickedAddActivityButton",
    Accounts_ClickedSectionHeader = "Accounts_ClickedSectionHeader",
    Accounts_ClickedTransactionSearchButton = "Accounts_ClickedTransactionSearchButton",
    Accounts_CreatedAccount = "Accounts_CreatedAccount",
    Accounts_CreatedPayeeRenamingRule = "Accounts_CreatedPayeeRenamingRule",
    Accounts_FilteredAccount = "Accounts_FilteredAccount",
    Accounts_FinishedReconciliation = "Accounts_FinishedReconciliation",
    Accounts_SearchedAccount = "Accounts_SearchedAccount",
    Accounts_SelectedAccountConnectionType = "Accounts_SelectedAccountConnectionType",
    Accounts_StartedReconciliation = "Accounts_StartedReconciliation",
    Accounts_UsedAccountsContextMenu = "Accounts_UsedAccountsContextMenu",
    Budget_AdjustedAutoAssignAmount = "Budget_AdjustedAutoAssignAmount",
    Budget_AppliedAutoAssign = "Budget_AppliedAutoAssign",
    Budget_AssignedMoney = "Budget_AssignedMoney",
    Budget_CanceledTargetSnooze = "Budget_CanceledTargetSnooze",
    Budget_ClickedAssignMoreMoneyToCreditCardPayment = "Budget_ClickedAssignMoreMoneyToCreditCardPayment",
    Budget_ClickedAutoAssign = "Budget_ClickedAutoAssign",
    Budget_ClickedCategoryNotes = "Budget_ClickedCategoryNotes",
    Budget_ClickedCategoryRecentMoves = "Budget_ClickedCategoryRecentMoves",
    Budget_ClickedCloseBudgetEditModeButton = "Budget_ClickedCloseBudgetEditModeButton",
    Budget_ClickedCollapse_expandAllMenuItem = "Budget_ClickedCollapse_expandAllMenuItem",
    Budget_ClickedCollapseAllGroups = "Budget_ClickedCollapseAllGroups",
    Budget_ClickedCoverCreditOverspending = "Budget_ClickedCoverCreditOverspending",
    Budget_ClickedCreateDebtPayoffTarget = "Budget_ClickedCreateDebtPayoffTarget",
    Budget_ClickedEnterBudgetEditModeButton = "Budget_ClickedEnterBudgetEditModeButton",
    Budget_ClickedExpandAllGroups = "Budget_ClickedExpandAllGroups",
    Budget_ClickedQuickBudget = "Budget_ClickedQuickBudget",
    Budget_ClickedSpendingTrendShortcut = "Budget_ClickedSpendingTrendShortcut",
    Budget_ClickedViewUncategorizedCreditTransactions = "Budget_ClickedViewUncategorizedCreditTransactions",
    Budget_ClosedBudgetEditMode = "Budget_ClosedBudgetEditMode",
    Budget_ClosedCoverOverspendingFlow = "Budget_ClosedCoverOverspendingFlow",
    Budget_ClosedMoneyMoveFlow = "Budget_ClosedMoneyMoveFlow",
    Budget_CompletedAddExpenses = "Budget_CompletedAddExpenses",
    Budget_CoveredOverspending = "Budget_CoveredOverspending",
    Budget_CreatedCategory = "Budget_CreatedCategory",
    Budget_CreatedCategoryGroup = "Budget_CreatedCategoryGroup",
    Budget_CreatedFocusedView = "Budget_CreatedFocusedView",
    Budget_CreatedGoal = "Budget_CreatedGoal",
    Budget_DeletedCategory = "Budget_DeletedCategory",
    Budget_DeletedCategoryGroup = "Budget_DeletedCategoryGroup",
    Budget_DeletedFocusedView = "Budget_DeletedFocusedView",
    Budget_DisabledCoverOverspendingOutro = "Budget_DisabledCoverOverspendingOutro",
    Budget_EnteredAutoAssignPreview = "Budget_EnteredAutoAssignPreview",
    Budget_EnteredBudgetEditMode = "Budget_EnteredBudgetEditMode",
    Budget_EnteredExpectedIncome = "Budget_EnteredExpectedIncome",
    Budget_ExpandedCreditCardPaymentDetail = "Budget_ExpandedCreditCardPaymentDetail",
    Budget_FilteredRecentMoves = "Budget_FilteredRecentMoves",
    Budget_FixedOverbudgeting = "Budget_FixedOverbudgeting",
    Budget_HidCategory = "Budget_HidCategory",
    Budget_HidCategoryGroup = "Budget_HidCategoryGroup",
    Budget_IncreasedMoneyInCreditCardPayment = "Budget_IncreasedMoneyInCreditCardPayment",
    Budget_LeftAssignMode = "Budget_LeftAssignMode",
    Budget_MovedMoney = "Budget_MovedMoney",
    Budget_OpenedRecentMoves = "Budget_OpenedRecentMoves",
    Budget_Overbudgeted = "Budget_Overbudgeted",
    Budget_ReorderedFocusedViews = "Budget_ReorderedFocusedViews",
    Budget_SavedAssignments = "Budget_SavedAssignments",
    Budget_SavedCreditCardPaymentTransaction = "Budget_SavedCreditCardPaymentTransaction",
    Budget_SelectedCategory = "Budget_SelectedCategory",
    Budget_SelectedFocusedView = "Budget_SelectedFocusedView",
    Budget_SetBudgetedAmount = "Budget_SetBudgetedAmount",
    Budget_SharedBudget = "Budget_SharedBudget",
    Budget_ShowedCategory = "Budget_ShowedCategory",
    Budget_ShowedCategoryGroup = "Budget_ShowedCategoryGroup",
    Budget_SnoozedTarget = "Budget_SnoozedTarget",
    Budget_StartedCoverOverspendingFlow = "Budget_StartedCoverOverspendingFlow",
    Budget_StartedMoneyMoveFlow = "Budget_StartedMoneyMoveFlow",
    Budget_ToggledPrivacyMode = "Budget_ToggledPrivacyMode",
    Budget_ToggledProgressBars = "Budget_ToggledProgressBars",
    Budget_TransferredBudgetOwnership = "Budget_TransferredBudgetOwnership",
    Budget_UnsharedBudget = "Budget_UnsharedBudget",
    Budget_UsedAssignKeypadAccessory = "Budget_UsedAssignKeypadAccessory",
    Budget_UsedBudgetContextMenu = "Budget_UsedBudgetContextMenu",
    Budget_ViewedBudgetContextMenu = "Budget_ViewedBudgetContextMenu",
    Budget_ViewedCategoryDetails = "Budget_ViewedCategoryDetails",
    Budget_ViewedCategoryMoves = "Budget_ViewedCategoryMoves",
    Budget_ViewedCreditCardPaymentInspector = "Budget_ViewedCreditCardPaymentInspector",
    Budget_ViewedNoExpensesAddedError = "Budget_ViewedNoExpensesAddedError",
    General_AskedToReviewApp = "General_AskedToReviewApp",
    General_ChangedMemberName = "General_ChangedMemberName",
    General_ClickedPreNotificationMessage = "General_ClickedPreNotificationMessage",
    General_ClickedShareBanner = "General_ClickedShareBanner",
    General_CreatedNewBudget = "General_CreatedNewBudget",
    General_DeviceConnectivityPlaceholder = "General_DeviceConnectivityPlaceholder",
    General_EnteredOverdraftState = "General_EnteredOverdraftState",
    General_LoggedIn = "General_LoggedIn",
    General_LoggedOut = "General_LoggedOut",
    General_MadeFreshStart = "General_MadeFreshStart",
    General_OpenedPushNotification = "General_OpenedPushNotification",
    General_SelectedPushNotificationConsent = "General_SelectedPushNotificationConsent",
    General_SharedYnab = "General_SharedYnab",
    Help_ClickedHowYnabWorksButton = "Help_ClickedHowYnabWorksButton",
    Help_ClickedShareVideo = "Help_ClickedShareVideo",
    Help_ExpandedHelpArticleDetail = "Help_ExpandedHelpArticleDetail",
    Help_SelectedPlaylistVideo = "Help_SelectedPlaylistVideo",
    Help_StartedHowYnabWorksVideo = "Help_StartedHowYnabWorksVideo",
    Help_SwipedQuickTip = "Help_SwipedQuickTip",
    Help_ViewedHelpResource = "Help_ViewedHelpResource",
    Import_ImportedDiTransactions = "Import_ImportedDiTransactions",
    Onboarding_AddedSuggestedCategory = "Onboarding_AddedSuggestedCategory",
    Onboarding_AdvancedCategoryWizardStep = "Onboarding_AdvancedCategoryWizardStep",
    Onboarding_AssignedSavingsToExpenses = "Onboarding_AssignedSavingsToExpenses",
    Onboarding_CanceledAddAccountStep = "Onboarding_CanceledAddAccountStep",
    Onboarding_ClickedExpandableTextCard = "Onboarding_ClickedExpandableTextCard",
    Onboarding_ClickedSuggestedCreditCardPaymentCategory = "Onboarding_ClickedSuggestedCreditCardPaymentCategory",
    Onboarding_ClosedAddAccountIntro = "Onboarding_ClosedAddAccountIntro",
    Onboarding_ClosedIntro = "Onboarding_ClosedIntro",
    Onboarding_ClosedOutro = "Onboarding_ClosedOutro",
    Onboarding_CompletedAddAccountIntro = "Onboarding_CompletedAddAccountIntro",
    Onboarding_CompletedAddAccountStep = "Onboarding_CompletedAddAccountStep",
    Onboarding_CompletedBudgetStep = "Onboarding_CompletedBudgetStep",
    Onboarding_CompletedOnboarding = "Onboarding_CompletedOnboarding",
    Onboarding_CreatedSavingsCategory = "Onboarding_CreatedSavingsCategory",
    Onboarding_OpenedOutro = "Onboarding_OpenedOutro",
    Onboarding_RatedFeeling = "Onboarding_RatedFeeling",
    Onboarding_RestartedCategoryWizard = "Onboarding_RestartedCategoryWizard",
    Onboarding_ReturnedToSettingTargetsOnboarding = "Onboarding_ReturnedToSettingTargetsOnboarding",
    Onboarding_SelectedSpendingRecipients = "Onboarding_SelectedSpendingRecipients",
    Onboarding_SkippedAddAccountStep = "Onboarding_SkippedAddAccountStep",
    Onboarding_SkippedSettingTargetsOnboarding = "Onboarding_SkippedSettingTargetsOnboarding",
    Onboarding_StartedAddAccountStep = "Onboarding_StartedAddAccountStep",
    Onboarding_TriggeredCategoryWizardExistingAccountError = "Onboarding_TriggeredCategoryWizardExistingAccountError",
    Onboarding_ViewedAddAccountsIntro = "Onboarding_ViewedAddAccountsIntro",
    Onboarding_ViewedMobileLandingScreen = "Onboarding_ViewedMobileLandingScreen",
    PageView_ViewedAccount = "PageView_ViewedAccount",
    PageView_ViewedAccountList = "PageView_ViewedAccountList",
    PageView_ViewedAgeOfMoneyReport = "PageView_ViewedAgeOfMoneyReport",
    PageView_ViewedAllAccounts = "PageView_ViewedAllAccounts",
    PageView_ViewedBudget = "PageView_ViewedBudget",
    PageView_ViewedCategoryActivity = "PageView_ViewedCategoryActivity",
    PageView_ViewedFamilySettings = "PageView_ViewedFamilySettings",
    PageView_ViewedHelp = "PageView_ViewedHelp",
    PageView_ViewedModalHelpArticle = "PageView_ViewedModalHelpArticle",
    PageView_ViewedNetWorthReport = "PageView_ViewedNetWorthReport",
    PageView_ViewedReflectTab = "PageView_ViewedReflectTab",
    PageView_ViewedReports = "PageView_ViewedReports",
    PageView_ViewedSettings = "PageView_ViewedSettings",
    PageView_ViewedSpendingBreakdown = "PageView_ViewedSpendingBreakdown",
    Reports_FilteredReport = "Reports_FilteredReport",
    SignUp_ClickedSignUp = "SignUp_ClickedSignUp",
    SignUp_CompletedSignUp = "SignUp_CompletedSignUp",
    Subscription_CanceledSubscription = "Subscription_CanceledSubscription",
    Subscription_FamilyInviteAccepted = "Subscription_FamilyInviteAccepted",
    Subscription_FamilyInviteExpired = "Subscription_FamilyInviteExpired",
    Subscription_FamilyMemberRemoved = "Subscription_FamilyMemberRemoved",
    Subscription_PurchasedSubscription = "Subscription_PurchasedSubscription",
    Subscription_RenewedSubscription = "Subscription_RenewedSubscription",
    Subscription_SentFamilyInvite = "Subscription_SentFamilyInvite",
    Subscription_StartedTrial = "Subscription_StartedTrial",
    Subscription_ViewedExpirationMessage = "Subscription_ViewedExpirationMessage",
    Subscription_ViewedPlanSelectionScreen = "Subscription_ViewedPlanSelectionScreen",
    Subscription_ViewedSubscriptionExpiredScreen = "Subscription_ViewedSubscriptionExpiredScreen",
    Subscription_ViewedTrialExpiredScreen = "Subscription_ViewedTrialExpiredScreen",
    Transaction_ApprovedTransaction = "Transaction_ApprovedTransaction",
    Transaction_ClickedAddTransaction = "Transaction_ClickedAddTransaction",
    Transaction_ClickedCategorySearchResult = "Transaction_ClickedCategorySearchResult",
    Transaction_ClickedCategorySuggestion = "Transaction_ClickedCategorySuggestion",
    Transaction_ClickedSplitTransaction = "Transaction_ClickedSplitTransaction",
    Transaction_ClickedSuggestionsTooltip = "Transaction_ClickedSuggestionsTooltip",
    Transaction_CreatedTransaction = "Transaction_CreatedTransaction",
    Transaction_DeletedTransaction = "Transaction_DeletedTransaction",
    Transaction_DuplicatedTransaction = "Transaction_DuplicatedTransaction",
    Transaction_EditedTransaction = "Transaction_EditedTransaction",
    Transaction_MatchedTransaction = "Transaction_MatchedTransaction",
    Transaction_MovedAccount = "Transaction_MovedAccount",
    Transaction_ProcessedTransaction = "Transaction_ProcessedTransaction",
    Transaction_RejectedTransaction = "Transaction_RejectedTransaction",
    Transaction_RemovePayeeLocation = "Transaction_RemovePayeeLocation",
    Transaction_SavedTransaction = "Transaction_SavedTransaction",
    Transaction_SearchedCategory = "Transaction_SearchedCategory",
    Transaction_SetAccount = "Transaction_SetAccount",
    Transaction_SetCategory = "Transaction_SetCategory",
    Transaction_SetClearedStatus = "Transaction_SetClearedStatus",
    Transaction_SetDate = "Transaction_SetDate",
    Transaction_SetFlag = "Transaction_SetFlag",
    Transaction_SetMemo = "Transaction_SetMemo",
    Transaction_SetPayee = "Transaction_SetPayee",
    Transaction_SetRepeat = "Transaction_SetRepeat",
    Transaction_SetTransactionAmount = "Transaction_SetTransactionAmount",
    Transaction_UnmatchedTransaction = "Transaction_UnmatchedTransaction",
    Transaction_UsedCalculator = "Transaction_UsedCalculator",
    Transaction_UsedEnterNow = "Transaction_UsedEnterNow",
    Transaction_UsedTransactionContextMenu = "Transaction_UsedTransactionContextMenu",
    Transaction_ViewedAllCategories = "Transaction_ViewedAllCategories",
    Accounts_AcceptedFallbackProvider = "Accounts_AcceptedFallbackProvider",
    Accounts_CheckedFallbackProviderInstitutionEligibility = "Accounts_CheckedFallbackProviderInstitutionEligibility",
    Accounts_ClickedAddConnection = "Accounts_ClickedAddConnection",
    Accounts_ClickedDynamicDefaultProviderInstitutionButton = "Accounts_ClickedDynamicDefaultProviderInstitutionButton",
    Accounts_ClickedDynamicDefaultProviderSearchButton = "Accounts_ClickedDynamicDefaultProviderSearchButton",
    Accounts_ClickedExistingConnection = "Accounts_ClickedExistingConnection",
    Accounts_ClickedViewAllTransactions = "Accounts_ClickedViewAllTransactions",
    Accounts_ClickedViewMyBudget = "Accounts_ClickedViewMyBudget",
    Accounts_ClickedViewOverspentCategories = "Accounts_ClickedViewOverspentCategories",
    Accounts_ClickedViewRemainingTransactions = "Accounts_ClickedViewRemainingTransactions",
    Accounts_ClosedAccount = "Accounts_ClosedAccount",
    Accounts_CombinedPayees = "Accounts_CombinedPayees",
    Accounts_CopiedPaydownAnalysisAmountToBudget = "Accounts_CopiedPaydownAnalysisAmountToBudget",
    Accounts_CreatedPaydownTarget = "Accounts_CreatedPaydownTarget",
    Accounts_DeletedBankAccount = "Accounts_DeletedBankAccount",
    Accounts_DeletedLoanActivity = "Accounts_DeletedLoanActivity",
    Accounts_EditedLoanActivity = "Accounts_EditedLoanActivity",
    Accounts_EncounteredDirectImportError = "Accounts_EncounteredDirectImportError",
    Accounts_EnteredAccountDetails = "Accounts_EnteredAccountDetails",
    Accounts_HidRunningBalance = "Accounts_HidRunningBalance",
    Accounts_PairedAccountWithExistingCategory = "Accounts_PairedAccountWithExistingCategory",
    Accounts_PairedAccountWithNewCategory = "Accounts_PairedAccountWithNewCategory",
    Accounts_RecordedLoanActivity = "Accounts_RecordedLoanActivity",
    Accounts_SelectedExistingBalancePaydownType = "Accounts_SelectedExistingBalancePaydownType",
    Accounts_SelectedInstitution = "Accounts_SelectedInstitution",
    Accounts_SelectedRegion = "Accounts_SelectedRegion",
    Accounts_ShowedRunningBalance = "Accounts_ShowedRunningBalance",
    Accounts_SkippedCategoryPairing = "Accounts_SkippedCategoryPairing",
    Accounts_StartedDebtAccountMigration = "Accounts_StartedDebtAccountMigration",
    Accounts_StartedDebtStartingBalanceErrorFix = "Accounts_StartedDebtStartingBalanceErrorFix",
    Accounts_UnpairedAccountFromCategory = "Accounts_UnpairedAccountFromCategory",
    Accounts_UpdateBalance = "Accounts_UpdateBalance",
    Accounts_UsedPaydownAnalysis = "Accounts_UsedPaydownAnalysis",
    Budget_ClickedCollapse_expandSidebar = "Budget_ClickedCollapse_expandSidebar",
    Budget_ClickedMonthNotes = "Budget_ClickedMonthNotes",
    Budget_ClickedPairCategoryWithLoan = "Budget_ClickedPairCategoryWithLoan",
    Budget_ClickedQuickBudgetFirstTime = "Budget_ClickedQuickBudgetFirstTime",
    Budget_EditedDebtTarget = "Budget_EditedDebtTarget",
    Budget_FinishedMoveMoneyFromTbb = "Budget_FinishedMoveMoneyFromTbb",
    Budget_ImportedCategoryTemplate = "Budget_ImportedCategoryTemplate",
    Budget_PairedCategoryWithLoan = "Budget_PairedCategoryWithLoan",
    Budget_SelectedCategoryGroup = "Budget_SelectedCategoryGroup",
    Budget_StartedCreateTarget = "Budget_StartedCreateTarget",
    Budget_StartedMoveMoneyFromTbb = "Budget_StartedMoveMoneyFromTbb",
    Budget_StartedRecordPaymentFromInspector = "Budget_StartedRecordPaymentFromInspector",
    Budget_ViewedKeyboardShortcuts = "Budget_ViewedKeyboardShortcuts",
    Budget_ViewedToBeBudgetedBreakdown = "Budget_ViewedToBeBudgetedBreakdown",
    General_AgedMoneyFirstTime = "General_AgedMoneyFirstTime",
    General_BlockedTracking = "General_BlockedTracking",
    General_ClickedCopyGroupInvite = "General_ClickedCopyGroupInvite",
    General_ClickedMultiSelectCheckbox = "General_ClickedMultiSelectCheckbox",
    General_ClickedReferralProgramButton = "General_ClickedReferralProgramButton",
    General_CreatedNewGroupInvite = "General_CreatedNewGroupInvite",
    General_ExportedBudget = "General_ExportedBudget",
    General_ExportedReport = "General_ExportedReport",
    General_ExportedTransactions = "General_ExportedTransactions",
    General_OpenedBudget = "General_OpenedBudget",
    General_RampedFeatureFlag = "General_RampedFeatureFlag",
    General_RemovedGroupMember = "General_RemovedGroupMember",
    General_SignedUpForWaitingList = "General_SignedUpForWaitingList",
    General_UpdatedUserProperties = "General_UpdatedUserProperties",
    General_UsedKeyboardShortcut = "General_UsedKeyboardShortcut",
    Help_ViewedGroupResources = "Help_ViewedGroupResources",
    Import_ClickedAddAppleWalletConnection = "Import_ClickedAddAppleWalletConnection",
    Import_ClosedPlaidWidget = "Import_ClosedPlaidWidget",
    Import_FinishedConnectionFlow = "Import_FinishedConnectionFlow",
    Import_ImportedFbiTransactions = "Import_ImportedFbiTransactions",
    Import_ImportedPendingTransactions = "Import_ImportedPendingTransactions",
    Import_ViewedPlaidWidget = "Import_ViewedPlaidWidget",
    Onboarding_AdvancedMintImporterStep = "Onboarding_AdvancedMintImporterStep",
    Onboarding_ClickedMigrateFrom = "Onboarding_ClickedMigrateFrom",
    Onboarding_ClickedWizardSignUp = "Onboarding_ClickedWizardSignUp",
    Onboarding_CompletedCreateGoalStep = "Onboarding_CompletedCreateGoalStep",
    Onboarding_CompletedMoveMoneyStep = "Onboarding_CompletedMoveMoneyStep",
    Onboarding_CompletedScheduleTransactionStep = "Onboarding_CompletedScheduleTransactionStep",
    Onboarding_DoneEstimatingExpenses = "Onboarding_DoneEstimatingExpenses",
    Onboarding_LaunchedCategoryWizard = "Onboarding_LaunchedCategoryWizard",
    Onboarding_PreviewedWizardResults = "Onboarding_PreviewedWizardResults",
    Onboarding_SelectedBudgetIntention = "Onboarding_SelectedBudgetIntention",
    Onboarding_SelectedIncomeSituation = "Onboarding_SelectedIncomeSituation",
    Onboarding_SelectedUsersSituation = "Onboarding_SelectedUsersSituation",
    Onboarding_SkippedAddingOnBudgetAccount = "Onboarding_SkippedAddingOnBudgetAccount",
    Onboarding_SkippedIntentionStep = "Onboarding_SkippedIntentionStep",
    Onboarding_SkippedOnboarding = "Onboarding_SkippedOnboarding",
    Onboarding_SkippedSituationStep = "Onboarding_SkippedSituationStep",
    Onboarding_StartedAssignMoneyStep = "Onboarding_StartedAssignMoneyStep",
    Onboarding_StartedWebFtueOnboarding = "Onboarding_StartedWebFtueOnboarding",
    Onboarding_StartedWebLegacyOnboarding = "Onboarding_StartedWebLegacyOnboarding",
    PageView_ViewedGroupDashboard = "PageView_ViewedGroupDashboard",
    PageView_ViewedGroupMembers = "PageView_ViewedGroupMembers",
    PageView_ViewedGroupSubscription = "PageView_ViewedGroupSubscription",
    PageView_ViewedIncomeVExpenseReport = "PageView_ViewedIncomeVExpenseReport",
    PageView_ViewedInvoices = "PageView_ViewedInvoices",
    PageView_ViewedManagePayees = "PageView_ViewedManagePayees",
    PageView_ViewedReconciliationPopover = "PageView_ViewedReconciliationPopover",
    PageView_ViewedReferralProgram = "PageView_ViewedReferralProgram",
    PageView_ViewedSpendingReport = "PageView_ViewedSpendingReport",
    PageView_ViewedSubscribe = "PageView_ViewedSubscribe",
    SignUp_CreatedUser = "SignUp_CreatedUser",
    SignUp_SignedMsa = "SignUp_SignedMsa",
    SignUp_VerifiedEmailAddress = "SignUp_VerifiedEmailAddress",
    SignUp_ViewedSignUpWithBudgetTemplate = "SignUp_ViewedSignUpWithBudgetTemplate",
    Social_AcceptedReferralInvite = "Social_AcceptedReferralInvite",
    Social_ClickedReferYnab = "Social_ClickedReferYnab",
    Social_SentReferralInvite = "Social_SentReferralInvite",
    Subscription_AddedBillingLocation = "Subscription_AddedBillingLocation",
    Subscription_ChangedGroupAttribute = "Subscription_ChangedGroupAttribute",
    Subscription_ChangedSubscriptionStatus = "Subscription_ChangedSubscriptionStatus",
    Subscription_ClickedSubscribeNow = "Subscription_ClickedSubscribeNow",
    Subscription_CreatedGroup = "Subscription_CreatedGroup",
    Subscription_DeletedAccount = "Subscription_DeletedAccount",
    Subscription_DeletedGroup = "Subscription_DeletedGroup",
    Subscription_ExtendedTrial = "Subscription_ExtendedTrial",
    Subscription_ExtendedTrialByCustomer = "Subscription_ExtendedTrialByCustomer",
    Subscription_ExtendedTrialBySupport = "Subscription_ExtendedTrialBySupport",
    Subscription_GiftedSubscription = "Subscription_GiftedSubscription",
    Subscription_JoinedGroup = "Subscription_JoinedGroup",
    Subscription_LapsedAppleSubscription = "Subscription_LapsedAppleSubscription",
    Subscription_LeftGroup = "Subscription_LeftGroup",
    Subscription_PurchasedFutureSubscription = "Subscription_PurchasedFutureSubscription",
    Subscription_SelectedCancellationReason = "Subscription_SelectedCancellationReason",
    Subscription_SelectedPayAsYouGo = "Subscription_SelectedPayAsYouGo",
    Subscription_SelectedPayUpFront = "Subscription_SelectedPayUpFront",
    Subscription_UpdatedBillingInfo = "Subscription_UpdatedBillingInfo",
    Subscription_UpdatedGroupBillingInfo = "Subscription_UpdatedGroupBillingInfo",
    Subscription_UpdatedGroupUser = "Subscription_UpdatedGroupUser",
    Support_ChangedPaymentGateway = "Support_ChangedPaymentGateway",
    Support_ChangedSupportAccess = "Support_ChangedSupportAccess",
    Support_ClickedChatWidget = "Support_ClickedChatWidget",
    Support_ContactedSupport = "Support_ContactedSupport",
    Support_ReceivedCaResponse = "Support_ReceivedCaResponse",
    Support_ReceivedSupportResponse = "Support_ReceivedSupportResponse",
    Transaction_ClickedBigBoxLink = "Transaction_ClickedBigBoxLink",
    Transaction_CustomizedFlagNames = "Transaction_CustomizedFlagNames",
    Transaction_FailedSubscriptionAction = "Transaction_FailedSubscriptionAction",
    Budget_DismissedTooltip = "Budget_DismissedTooltip",
    Help_ClickedPreviousMonthOverspendingHelpBanner = "Help_ClickedPreviousMonthOverspendingHelpBanner",
    PageView_ViewedPreviousMonthOverspendingHelp = "PageView_ViewedPreviousMonthOverspendingHelp",
    PageView_ViewedPreviousMonthOverspendingHelpBanner = "PageView_ViewedPreviousMonthOverspendingHelpBanner",
    Subscription_SkippedPlanSelection = "Subscription_SkippedPlanSelection",
    Subscription_ViewedIneligibleForTrialScreen = "Subscription_ViewedIneligibleForTrialScreen",
    Subscription_ViewedSubscriptionChangeScreen = "Subscription_ViewedSubscriptionChangeScreen",
}

export enum AnalyticsProperties {
    AcceptedPlatform = "Accepted Platform",
    AcceptedUserType = "Accepted User Type",
    AccountsContextMenuSelection = "Accounts Context Menu Selection",
    AccountsFilter = "Accounts Filter",
    AccountType = "Account Type",
    AdjustmentAmount = "Adjustment Amount",
    AdjustmentTransactionCreated = "Adjustment Transaction Created",
    AmountOfCategoriesInMove = "Amount of Categories in Move",
    AmountOfOverspentCategories = "Amount of Overspent Categories",
    ApprovedTransactionMethod = "Approved Transaction Method",
    Article = "Article",
    Auto_assignLocation = "Auto-Assign Location",
    BudgetContextMenuSelection = "Budget Context Menu Selection",
    CategoriesFilter = "Categories Filter",
    CategoryWizardResponse = "Category Wizard Response",
    ClearedStatus = "Cleared Status",
    CompletedShare = "Completed Share",
    ConnectionType = "Connection Type",
    Coverage = "Coverage",
    CreditCardBalanceAfterPayment = "Credit Card Balance After Payment",
    CreditCardPaymentStatus = "Credit Card Payment Status",
    CreditCategoryStatus = "Credit Category Status",
    Currency = "Currency",
    CustomFlagName = "Custom Flag Name",
    DateFilter = "Date Filter",
    DeletedTransactionMethod = "Deleted Transaction Method",
    Detail = "Detail",
    DeviceConnectivity = "Device Connectivity",
    DuplicatedTransactionMethod = "Duplicated Transaction Method",
    ExitSurveyKey = "Exit Survey Key",
    FeelingRate = "Feeling Rate",
    FinancialInstitution = "Financial Institution",
    FromCategoryType = "From Category Type",
    GoalType = "Goal Type",
    ImportedAccountType = "Imported Account Type",
    InitialDirection = "Initial Direction",
    InvitedUserType = "Invited User Type",
    InviteType = "Invite Type",
    Location = "Location",
    LogInMethod = "Log In Method",
    MatchedTransactionMethod = "Matched Transaction Method",
    MonthDelta = "Month Delta",
    MovesTypeFilter = "Moves Type Filter",
    MultipleWarnings = "Multiple Warnings",
    NewTarget = "New Target",
    NoteInitialState = "Note Initial State",
    NotificationType = "Notification Type",
    NumberOfSharedBudgets = "Number of Shared Budgets",
    On_off = "On/Off",
    Operation = "Operation",
    OptedIn = "Opted In",
    OutroState = "Outro State",
    OverspendingCoverage = "Overspending Coverage",
    PendingTransactionsFilter = "Pending Transactions Filter",
    PercentageOfCategoriesThatHaveTargets = "Percentage of categories that have targets",
    PercentageOfCategoriesWhichWereAssignedTo = "Percentage of categories which were assigned to",
    PercentageOfRemainingInRta = "Percentage of remaining in RTA",
    Position = "Position",
    ProcessAction = "Process Action",
    QuickBudgetCount = "Quick Budget Count",
    QuickBudgetMethod = "Quick Budget Method",
    ReconciledTransactionFilter = "Reconciled Transaction Filter",
    ReflectionPath = "Reflection Path",
    RejectedTransactionMethod = "Rejected Transaction Method",
    RemovedBy = "Removed By",
    RenamingPath = "Renaming Path",
    RepeatValue = "Repeat Value",
    Resource = "Resource",
    ResponsePosition = "Response Position",
    Result = "Result",
    RtaState = "RTA State",
    Scheduled = "Scheduled",
    ScheduledTransactionsFilter = "Scheduled Transactions Filter",
    SearchedAccount = "Searched Account",
    SearchedApproved = "Searched Approved",
    SearchedCategory = "Searched Category",
    SearchedCleared = "Searched Cleared",
    SearchedDate = "Searched Date",
    SearchedFlag = "Searched Flag",
    SearchedInflow = "Searched Inflow",
    SearchedMemo = "Searched Memo",
    SearchedNeedsCategory = "Searched Needs Category",
    SearchedOutflow = "Searched Outflow",
    SearchedPayee = "Searched Payee",
    SearchedReconciled = "Searched Reconciled",
    SearchedUnapproved = "Searched Unapproved",
    SearchedUncleared = "Searched Uncleared",
    SearchedUnreconciled = "Searched Unreconciled",
    SectionHeaderEndState = "Section Header End State",
    SectionHeaderType = "Section Header Type",
    SetAccountMethod = "Set Account Method",
    SetCategorySource = "Set Category Source",
    SetClearedStatusMethod = "Set Cleared Status Method",
    SetFlagMethod = "Set Flag Method",
    ShareLocation = "Share Location",
    ShareMethod = "Share Method",
    SignUpMethod = "Sign Up Method",
    SpendingRecipientType = "Spending Recipient Type",
    SpendingTrend = "Spending Trend",
    StartedReconciliationMethod = "Started Reconciliation Method",
    State = "State",
    StopImportingTransactionsSelection = "Stop Importing Transactions Selection",
    SubscriptionExternalProductId = "Subscription External Product Id",
    SubscriptionPlan = "Subscription Plan",
    SubscriptionProvider = "Subscription Provider",
    SubscriptionType = "Subscription Type",
    SufficientIncome = "Sufficient Income",
    SuggestedCategoryName = "Suggested Category Name",
    TargetSnoozeLocation = "Target Snooze Location",
    ToCategoryType = "To Category Type",
    TransactionContextMenuSelection = "Transaction Context Menu Selection",
    TransactionCount = "Transaction Count",
    TransactionState = "Transaction State",
    TransactionsType = "Transactions Type",
    TransactionValue = "Transaction Value",
    UnfundedPayment = "Unfunded Payment",
    UnmatchedTransactionMethod = "Unmatched Transaction Method",
    UnshareMethod = "Unshare Method",
    UsedEnterNowMethod = "Used Enter Now Method",
    UsedKeypad = "Used Keypad",
    UsedMath = "Used Math",
    UsedShortcut = "Used Shortcut",
    UsedSliderWithASnapPoint = "Used Slider with a Snap Point",
    UsedSliderWithoutASnapPoint = "Used Slider without a Snap Point",
    UserJourneyState = "User Journey State",
    UtmCampaign = "UTM Campaign",
    UtmContent = "UTM Content",
    UtmMedium = "UTM Medium",
    UtmSource = "UTM Source",
    ViewedActivityMethod = "Viewed Activity Method",
    ViewType = "View type",
    WizardStep = "Wizard Step",
    YnabPlatform = "YNAB Platform",
    ZeroBalance = "Zero Balance",
    Action = "Action",
    ActivityType = "Activity Type",
    Attribute = "Attribute",
    BudgetIntention = "Budget Intention",
    BudgetSituation_Income = "Budget Situation: Income",
    BudgetSituation_Users = "Budget Situation: Users",
    CategoryTemplate = "Category Template",
    ConversationCategory = "Conversation Category",
    ConversationId = "Conversation ID",
    ConversationSource = "Conversation Source",
    CorrectedMatch = "Corrected Match",
    Count = "Count",
    DebtTargetInputType = "Debt Target Input Type",
    DestinationUrl = "Destination URL",
    DirectImportCurrentBalanceMatch = "Direct Import Current Balance Match",
    DirectImportProvider = "Direct Import Provider",
    DirectImportProviderError = "Direct Import Provider Error",
    Escrow = "Escrow",
    ExitSurveyOption = "Exit Survey Option",
    Expand_collapsedSidebarMethod = "Expand/Collapsed Sidebar Method",
    ExpirationIntent = "Expiration Intent",
    ExtendedBy = "Extended By",
    FbiError = "FBI Error",
    FeatureFlag = "Feature Flag",
    FileType = "File Type",
    FlagsWithCustomNames = "Flags with Custom Names",
    FromTbbOrToTbb = "From TBB or to TBB",
    GroupId = "Group ID",
    GroupName = "Group Name",
    GroupSeatsLimit = "Group Seats Limit",
    GroupSeatsUsed = "Group Seats Used",
    GroupUserId = "Group User ID",
    ImportedSelectCategories = "Imported Select Categories",
    ImporterStep = "Importer Step",
    InstitutionCount = "Institution Count",
    Interest = "Interest",
    KeyboardShortcut = "Keyboard Shortcut",
    MigrationPath = "Migration Path",
    MigrationSource = "Migration Source",
    NewPaymentGateway = "New Payment Gateway",
    OnboardingPath = "Onboarding Path",
    OpenConversationCount = "Open Conversation Count",
    OpenConversationsIds = "Open Conversations IDs",
    Outcome = "Outcome",
    PageName = "Page Name",
    PairedCategory = "Paired Category",
    PaydownType = "Paydown Type",
    Permission = "Permission",
    PlaidAccountsLinkedCount = "Plaid Accounts Linked Count",
    PlaidChosePreselectedInstitution = "Plaid Chose Preselected Institution",
    PlaidExitReasonSummary = "Plaid Exit Reason Summary",
    PlaidPhoneField = "Plaid Phone Field",
    PlaidSavedAccountsScreen = "Plaid Saved Accounts Screen",
    PlaidSavedInstitutionsScreen = "Plaid Saved Institutions Screen",
    PlaidWidgetOpenDuration = "Plaid Widget Open Duration",
    ProcessingState = "Processing State",
    ReconciliationStep = "Reconciliation Step",
    ReferralMethod = "Referral Method",
    Region = "Region",
    ReportType = "Report Type",
    SearchTerm = "Search Term",
    SignUpSource = "Sign Up Source",
    SimulatorInputType = "Simulator Input Type",
    Status = "Status",
    SubscriptionStatus = "Subscription Status",
    SupportMethod = "Support Method",
    TbbAmount = "TBB Amount",
    TransactionFailedGateway = "Transaction Failed Gateway",
    TransactionFailedReason = "Transaction Failed Reason",
    TransactionFailedSource = "Transaction Failed Source",
    TrialExtensionDuration = "Trial Extension Duration",
    ViewedKeyboardShortcutsMethod = "Viewed Keyboard Shortcuts Method",
    WaitingList = "Waiting List",
    WidgetReason = "Widget Reason",
    WidgetSelected = "Widget Selected",
    OverspendingType = "Overspending Type",
}

export enum AnalyticsPropertyValues {
    Account = "Account",
    AccountEditor = "Account Editor",
    AccountList = "Account List",
    AccountWidget = "Account Widget",
    ActionBar = "Action Bar",
    ActivityPopover = "Activity Popover",
    Addition = "Addition",
    AddTransactionOption = "Add Transaction Option",
    All = "All",
    AllAccounts = "All Accounts",
    AllDates = "All Dates",
    Android = "Android",
    Annual = "Annual",
    Apple = "Apple",
    ApprovalPopover = "Approval Popover",
    ApproveAll = "Approve All",
    Approved = "Approved",
    ApproveOption = "Approve Option",
    Asset = "Asset",
    Assigned = "Assigned",
    AssignMode = "Assign Mode",
    AssignMoneyOption = "Assign Money Option",
    AutoLoan = "Auto Loan",
    Automatic = "Automatic",
    AvailableForPayment = "Available for Payment",
    AverageMonthlySpending = "Average Monthly Spending",
    Banner = "Banner",
    BlogPost = "Blog Post",
    BlurpleRain = "Blurple Rain",
    Book = "Book",
    Braze = "Braze",
    Budget = "Budget",
    BudgetMenu = "Budget Menu",
    BudgetRow = "Budget Row",
    BudgetRowContextMenu = "Budget Row Context Menu",
    BuildABudget = "Build A Budget",
    BulkEdit = "Bulk Edit",
    BusinessCaseForWellness = "Business Case for Wellness",
    Cancelled = "Cancelled",
    Cash = "Cash",
    CashOverspendingHelpArticle = "Cash Overspending Help Article",
    Categorized = "Categorized",
    CategorizeOption = "Categorize Option",
    CategoryDetails = "Category Details",
    CategoryGroup = "Category Group",
    CategoryInspector = "Category Inspector",
    CategorySelectionIndex = "Category Selection Index",
    CategorySheet = "Category Sheet",
    Checking = "Checking",
    Cleared = "Cleared",
    ClearUnclearOption = "Clear/Unclear Option",
    CloseAccountReOpenAccountOption = "Close Account / Re-Open Account Option",
    Collapsed = "Collapsed",
    Complete = "Complete",
    Completed = "Completed",
    CompletedWizard = "Completed Wizard",
    Confident = "confident",
    ConsumerLoan = "Consumer Loan",
    CostToBeMe = "Cost To Be Me",
    CoveredOverspending = "Covered Overspending",
    CoverOverspending = "Cover Overspending",
    CoverOverspendingFlow = "Cover Overspending Flow",
    CreateGoalEditGoalOption = "Create Goal / Edit Goal Option",
    CreditCard = "Credit Card",
    CreditCardCreditOverspending = "Credit Card Credit Overspending",
    CreditCardMinimumPayments = "Credit Card Minimum Payments",
    CreditCardsVideo = "Credit Cards Video",
    CreditCardUncategorizedTransactions = "Credit Card Uncategorized Transactions",
    CreditCardUnderfunded = "Credit Card Underfunded",
    CreditCardUnfundedPaymentMade = "Credit Card Unfunded Payment Made",
    CreditOverspending = "CreditOverspending",
    CreditOverspendingHelpArticle = "Credit Overspending Help Article",
    CurrentBalance = "Current Balance",
    Custom = "Custom",
    CustomView = "Custom View",
    Daily = "Daily",
    Dark = "Dark",
    DarkMode = "Dark Mode",
    DebtInterstitial = "Debt Interstitial",
    DebtQuestion = "Debt Question",
    Deleted = "Deleted",
    DeleteOption = "Delete Option",
    Documentation = "Documentation",
    Downgrade = "Downgrade",
    DuplicateOption = "Duplicate Option",
    EditAccountOption = "Edit Account Option",
    EditImportedTransaction = "Edit Imported Transaction",
    EditMenu = "Edit Menu",
    EditPlan = "Edit Plan",
    EditTargetScreen = "Edit Target Screen",
    Email = "Email",
    EmailTemplates = "Email Templates",
    EmployeeEngagementPlan = "Employee Engagement Plan",
    Empty = "Empty",
    EnterNowOption = "Enter Now Option",
    Equals = "Equals",
    Every_3Months = "Every 3 Months",
    Every_4Months = "Every 4 Months",
    Every_4Weeks = "Every 4 Weeks",
    EveryOtherMonth = "Every Other Month",
    EveryOtherWeek = "Every Other Week",
    EveryOtherYear = "Every Other Year",
    Exact = "Exact",
    ExistingConnection = "Existing Connection",
    ExistingUser = "Existing User",
    Expanded = "Expanded",
    False = "False",
    Faq = "FAQ",
    FeelingsInterstitial = "Feelings Interstitial",
    FeelingsQuestion = "Feelings Question",
    Filled = "Filled",
    FinalQuestion = "Final Question",
    FinancialWellnessPlan = "Financial Wellness Plan",
    FlagOption = "Flag Option",
    Flourish = "Flourish",
    FromSelectedCategory = "From Selected Category",
    FtueClassicEditMode = "FTUE Classic Edit Mode",
    FullBudget = "Full Budget",
    FullyOrOverFunded = "FullyOrOverFunded",
    FullyPaid = "Fully Paid",
    GlobalInspector = "Global Inspector",
    GoalTypes = "Goal Types",
    Google = "Google",
    GoogleInAppPurchase = "Google In-App Purchase",
    HaveABalance = "Have a Balance",
    HaveABalanceByDate = "Have a Balance by Date",
    Helpscout = "Helpscout",
    HidePendingTransactions = "Hide Pending Transactions",
    HideReconciledTransactions = "Hide Reconciled Transactions",
    HideScheduledTransactions = "Hide Scheduled Transactions",
    HomeQuestion = "Home Question",
    Ignored = "Ignored",
    InAppPurchase = "In-App Purchase",
    IncomeVExpense = "Income v Expense",
    Initial = "Initial",
    Inspector = "Inspector",
    InspirationalInterstitial = "Inspirational Interstitial",
    InstitutionList = "Institution List",
    Intentional = "Intentional",
    Intercom = "Intercom",
    InviteTemplates = "Invite Templates",
    InvitingYourTeam = "Inviting Your Team",
    Ios = "IOS",
    KeyboardShortcut = "Keyboard Shortcut",
    Keypad = "Keypad",
    KeypadBanner = "Keypad Banner",
    Kids = "Kids",
    Kustomer = "Kustomer",
    LastYear = "Last Year",
    Latest_12Months = "Latest 12 Months",
    Latest_3Months = "Latest 3 Months",
    Latest_6Months = "Latest 6 Months",
    Liability = "Liability",
    Light = "Light",
    LineOfCredit = "Line of Credit",
    Linked = "Linked",
    Listen = "Listen",
    Loading = "Loading",
    Local = "Local",
    LongPressCategoryOnBudgetScreen = "Long Press Category on Budget Screen",
    LongPressCategoryOnBudgetScreenIOs = "Long press category on Budget screen (iOS)",
    Luxe = "Luxe",
    ManagePayees = "Manage Payees",
    MatchDetails = "Match Details",
    Matched = "Matched",
    MatchingPopover = "Matching Popover",
    MatchSystem = "Match System",
    MatchUnmatchOption = "Match/Unmatch Option",
    MedicalDebt = "Medical Debt",
    MembersSurvey = "Members Survey",
    MoneyAvailableView = "Money Available View",
    Monthly = "Monthly",
    MonthlyDebtPaymentPaired = "Monthly Debt Payment Paired",
    MonthlyDebtPaymentUnpaired = "Monthly Debt Payment Unpaired",
    MonthlyFundingGoal = "Monthly Funding Goal",
    MonthlyRolloverVideo = "Monthly Rollover Video",
    Mortgage = "Mortgage",
    MortgageQuestion = "Mortgage Question",
    Moved = "Moved",
    MovedMoney = "Moved Money",
    MoveMoney = "Move Money",
    MoveMoneyFlow = "Move Money Flow",
    MoveMoneyOption = "Move Money Option",
    MoveMoneyPopover = "Move Money Popover",
    MoveOption = "Move Option",
    MyPartner = "My Partner",
    MyPlan = "My Plan",
    Myself = "Myself",
    NameQuestion = "Name Question",
    NeededBiWeekly = "Needed Bi-Weekly",
    NeededByDate = "Needed by Date",
    NeededByDateRepeats = "Needed by Date Repeats",
    NeededMonthly = "Needed Monthly",
    NeededWeekly = "Needed Weekly",
    NeededWithoutDate = "Needed without Date",
    NeededYearly = "Needed Yearly",
    Negative = "Negative",
    NetWorth = "Net Worth",
    Never = "Never",
    NewConnection = "New Connection",
    NewMemberGuide = "New Member Guide",
    NewMonthBlogPost = "New Month Blog Post",
    NewUser = "New User",
    None = "None",
    NotApplicable = "not applicable",
    Offline = "Offline",
    Onboarding = "Onboarding",
    Online = "Online",
    Original = "Original",
    OtherAdults = "Other Adults",
    OtherDebt = "Other Debt",
    Overbudgeting = "Overbudgeting",
    Overdraft = "Overdraft",
    OverfundedView = "Overfunded View",
    OverspendingOrUnderbudgetingVideo = "Overspending or Underbudgeting Video",
    OverspentCategories = "Overspent Categories",
    OverspentView = "Overspent View",
    Partial = "Partial",
    PartnerInterstitial = "Partner Interstitial",
    PartnerQuestion = "Partner Question",
    PayeePopover = "Payee Popover",
    PayOffBalanceByDate = "Pay Off Balance by Date",
    PaySpecificAmountEachMonth = "Pay Specific Amount Each Month",
    Pending = "Pending",
    PersonalLoan = "Personal Loan",
    Pets = "Pets",
    PinUnpinCategoryOption = "Pin / Unpin Category Option",
    PlanManager = "Plan Manager",
    PlanMember = "Plan Member",
    Podcast = "Podcast",
    PopularInstitutions = "Popular Institutions",
    Positive = "Positive",
    Posted = "Posted",
    PostedPending = "Posted,Pending",
    PrioritiesInterstitial = "Priorities Interstitial",
    ProcessTransactions = "Process Transactions",
    Provisional = "Provisional",
    Rainbow = "Rainbow",
    Read = "Read",
    RecordPayment = "Record Payment",
    Recurly = "Recurly",
    RefillUpToByDate = "Refill Up to by Date",
    RefillUpToCustomRepeat = "Refill Up to, Custom Repeat",
    RefillUpToMonthly = "Refill Up to Monthly",
    RefillUpToWeekly = "Refill Up to Weekly",
    RefillUpToYearly = "Refill Up to Yearly",
    RegisterActionBar = "Register Action Bar",
    RegularSpendQuestion = "Regular Spend Question",
    Rejected = "Rejected",
    RejectOption = "Reject Option",
    Renewal = "Renewal",
    Resent = "Resent",
    RetroGame = "Retro Game",
    RightClickMenu = "Right-Click Menu",
    RolloutGuide = "Rollout Guide",
    RtaBanner = "RTA Banner",
    RuleThreePodcast = "Rule Three Podcast",
    Savings = "Savings",
    SavingsQuestion = "Savings Question",
    Scheduled = "Scheduled",
    ScheduledPending = "Scheduled, Pending",
    Scrolled = "Scrolled",
    Searched = "Searched",
    SeasonAutumn = "Season Autumn",
    SeasonSpring = "Season Spring",
    SeasonSummer = "Season Summer",
    SeasonWinter = "Season Winter",
    Self = "Self",
    SelfReportedSourceQuestion = "Self-Reported Source Question",
    Server = "Server",
    SetAsideAnotherByDate = "Set Aside Another by Date",
    SetAsideAnotherCustomRepeat = "Set Aside Another, Custom Repeat",
    SetAsideAnotherMonthly = "Set Aside Another Monthly",
    SetAsideAnotherWeekly = "Set Aside Another Weekly",
    SetAsideAnotherYearly = "Set Aside Another Yearly",
    SettingsDropdown = "Settings Dropdown",
    SettingsMenu = "Settings Menu",
    SettingsPage = "Settings Page",
    ShowAll = "Show All",
    ShowNone = "Show None",
    ShowPendingTransactions = "Show Pending Transactions",
    ShowReconciledTransactions = "Show Reconciled Transactions",
    ShowScheduledTransactions = "Show Scheduled Transactions",
    ShowSelection = "Show Selection",
    Sidebar = "Sidebar",
    SnoozedView = "Snoozed View",
    SnoozeUnsnoozeTargetOption = "Snooze/Unsnooze Target Option",
    SpendingBreakdown = "Spending Breakdown",
    SpendingReport = "Spending Report",
    SpendingTrends = "Spending Trends",
    SpentLastMonth = "Spent Last Month",
    Stable = "stable",
    StartedWizard = "Started Wizard",
    Stressed = "stressed",
    StudentLoan = "Student Loan",
    SubscriptionsQuestion = "Subscriptions Question",
    Subtraction = "Subtraction",
    Suggestions = "Suggestions",
    Support = "Support",
    Swipe = "Swipe",
    SwipeLeftIOs = "Swipe left (iOS)",
    TargetCategoryBalance = "Target Category Balance",
    TargetCategoryBalanceByDate = "Target Category Balance by Date",
    TargetMet = "Target Met",
    Teens_13 = "Teens (13+)",
    ThisMonth = "This Month",
    ThisYear = "This Year",
    ThreeDimensions = "Three Dimensions",
    ToBeBudgeted = "To Be Budgeted",
    ToggledOff = "Toggled Off",
    ToggledOn = "Toggled On",
    ToSelectedCategory = "To Selected Category",
    TransactionDetailsView = "Transaction Details View",
    TransactionImportList = "Transaction Import List",
    TransactionRow = "Transaction Row",
    TransactionRowContextMenu = "Transaction Row Context Menu",
    TransportationQuestion = "Transportation Question",
    True = "True",
    TrueExpensesInterstitial = "True Expenses Interstitial",
    TrueExpensesQuestion = "True Expenses Question",
    TwiceAMonth = "Twice a Month",
    TwiceAYear = "Twice a Year",
    Unavailable = "Unavailable",
    UncategorizedTransactions = "UncategorizedTransactions",
    Uncleared = "Uncleared",
    Underfunded = "Underfunded",
    UnderfundedView = "Underfunded View",
    UnfundedPaymentMade = "UnfundedPaymentMade",
    UniqueId = "Unique ID#",
    Unknown = "Unknown",
    Unlinked = "Unlinked",
    Unmatched = "Unmatched",
    Unshared = "Unshared",
    Unsure = "unsure",
    UserEntered = "User-Entered",
    VideoCoursePlaylist = "Video Course Playlist",
    ViewActivityOption = "View Activity Option",
    ViewAssignedOption = "View Assigned Option",
    Vintage = "Vintage",
    Watch = "Watch",
    Web = "Web",
    Weekly = "Weekly",
    WorkshopPlaylist = "Workshop Playlist",
    Workshops = "Workshops",
    Yearly = "Yearly",
    YouTubePlaylist = "YouTube Playlist",
    Zero = "Zero",
    Abandoned = "Abandoned",
    Admin = "Admin",
    AppUpdateRequired = "AppUpdateRequired",
    Attempted = "Attempted",
    BalanceMatch = "Balance Match",
    BalanceMismatch = "Balance Mismatch",
    BalanceNotAvailable = "Balance Not Available",
    BalanceOutOfDate = "Balance Out of Date",
    BalancePendingMatch = "Balance Pending Match",
    BalanceUnclearedMatch = "Balance Uncleared Match",
    BillingError = "Billing Error",
    BreakThePaycheckToPaycheckCycle = "Break the Paycheck to Paycheck Cycle",
    CannotImportMultipleCsvFiles = "Cannot Import Multiple CSV Files",
    CannotImportMultipleQifFiles = "Cannot Import Multiple QIF Files",
    Changed = "Changed",
    Charge = "Charge",
    Chat = "Chat",
    ClosedMintMigration = "Closed Mint Migration",
    CompletedMintVideoStep = "Completed Mint Video Step",
    ConnectionInErrorState = "Connection in Error State",
    ConnectionsUpdateMaintenance = "ConnectionsUpdateMaintenance",
    ConnectionsUpdateRequired = "ConnectionsUpdateRequired",
    Csv = "CSV",
    CsvNotFormattedCorrectly = "CSV Not Formatted Correctly",
    DeletedConnection = "DeletedConnection",
    DeselectAll = "Deselect All",
    Difference = "Difference",
    DirectImportBalanceImport = "Direct Import Balance Import",
    DirectImportBalanceMatch = "Direct Import Balance Match",
    DirectImportBalanceMismatch = "Direct Import Balance Mismatch",
    DirectImportBalanceMismatchPending = "Direct Import Balance Mismatch Pending",
    DirectImportBalanceMismatchUncleared = "Direct Import Balance Mismatch Uncleared",
    Disabled = "Disabled",
    DueDate = "Due Date",
    DuplicateConnection = "DuplicateConnection",
    Edited = "Edited",
    Enabled = "Enabled",
    EnterBalance = "Enter Balance",
    Escrow = "Escrow",
    Estimated = "Estimated",
    Europe = "Europe",
    EuropeDirectImport = "Europe Direct Import",
    Facebook = "Facebook",
    Failed = "Failed",
    Forethought = "Forethought",
    ForethoughtDisabled = "Forethought Disabled",
    ForethoughtEnabled = "Forethought Enabled",
    FromTbb = "From TBB",
    GiftAnnual = "gift_annual",
    GroupRole = "Group Role",
    GroupType = "Group Type",
    HelpIMBroke = "Help! I'm Broke",
    InterestCharge = "Interest Charge",
    Link = "Link",
    MalformedOfxFile = "Malformed OFX File",
    Menu = "Menu",
    Mint = "Mint",
    MonthlyPayment = "Monthly Payment",
    MoreTransactions = "More Transactions",
    Multiple = "Multiple",
    MustOwnConnection = "MustOwnConnection",
    Mx = "MX",
    Neither = "Neither",
    No = "No",
    NoAccountsFound = "NoAccountsFound",
    NoInstitutionFoundDuringSearch = "NoInstitutionFoundDuringSearch",
    NorthAmerica = "North America",
    NotAvailableForPurchase = "Not Available For Purchase",
    NotShown = "Not Shown",
    OAuthError = "OAuthError",
    Ofx = "OFX",
    OneTimeExtra = "One Time Extra",
    OneTimePayment = "One Time Payment",
    OpenKustomerConversations = "Open Kustomer Conversations",
    Other = "Other",
    Overspent = "Overspent",
    OverspentMoreTransactions = "Overspent & More Transactions",
    Partner = "Partner",
    Payment = "Payment",
    PayoffDate = "Payoff Date",
    PayOffDebt = "Pay Off Debt",
    Plaid = "Plaid",
    Qfx = "QFX",
    Qif = "QIF",
    Reconciled = "Reconciled",
    ReconciliationComplete = "Reconciliation Complete",
    Refund = "Refund",
    RefusedPriceChange = "Refused Price Change",
    Regular = "Regular",
    SavingForABigPurchaseOrVacation = "Saving for a Big Purchase or Vacation",
    SavingForRetirement = "Saving for Retirement",
    SelectAll = "Select All",
    Selected = "Selected",
    SelectedOnboardingPath = "Selected Onboarding Path",
    ServiceUnavailable = "ServiceUnavailable",
    Shown = "Shown",
    ShownButUnwanted = "Shown But Unwanted",
    SignUp = "Sign-up",
    Skip = "Skip",
    Skipped = "Skipped",
    SkippedAfterAttempt = "Skipped After Attempt",
    Solo = "Solo",
    Spending = "Spending",
    SubscribedAnnual = "subscribed_annual",
    SubscribedMonthly = "subscribed_monthly",
    SubscriptionExpired = "subscription_expired",
    SubscriptionPaused = "subscription_paused",
    Succeeded = "Succeeded",
    Target = "Target",
    TbbNegative = "TBB Negative",
    TbbPositive = "TBB Positive",
    TbbZero = "TBB Zero",
    ToTbb = "To TBB",
    Trial = "trial",
    TrialExpired = "trial_expired",
    TrialReinstated = "trial_reinstated",
    TroubleConnecting = "TroubleConnecting",
    TroublePerformingConnectionsUpdate = "TroublePerformingConnectionsUpdate",
    Truelayer = "Truelayer",
    Twitter = "Twitter",
    UnknownError = "Unknown Error",
    UnsupportedConnection = "UnsupportedConnection",
    UnsupportedFileType = "Unsupported File Type",
    UploadedMintFile = "Uploaded Mint File",
    User = "User",
    Vanilla = "Vanilla",
    Variable = "Variable",
    Verified = "Verified",
    VoluntaryCancel = "Voluntary Cancel",
    Wellness = "Wellness",
    Yes = "Yes",
    YnabForGood = "YNAB for Good",
    ZeroTransactionsToImport = "Zero Transactions to Import",
    BothCashAndCreditOverspending = "Both Cash and Credit Overspending",
    CashOverspending = "Cash Overspending",
    CreditOverspendingOnCashTransaction = "Credit Overspending on Cash Transaction",
}

export enum AnalyticsUserPropertyValues {
    AppInstallDate = "App Install Date",
    AppLock = "App Lock",
    DifferentiateWithoutColor = "Differentiate Without Color",
    DynamicTypeSize = "Dynamic Type Size",
    FamilyId = "Family ID",
    FamilySeatsUsed = "Family Seats Used",
    FamilyUserRole = "Family User Role ",
    HasEverJoinedFamily = "Has Ever Joined Family",
    IosAlternativeAppIcon = "iOS Alternative App Icon",
    LocationAccess = "Location Access",
    MemberName = "Member Name ",
    MobileInAppLightDarkMode = "Mobile In App Light Dark Mode",
    PreRegistrationFeatureFlags = "Pre Registration Feature Flags",
    PushNotificationsAccess = "Push Notifications Access",
    CompanySize = "Company Size",
    GroupId = "Group ID",
    GroupName = "Group Name",
    GroupSeatsLimit = "Group Seats Limit",
    GroupSeatsUsed = "Group Seats Used",
    GroupType = "Group Type",
    GroupUserId = "Group User ID",
    GroupUserRole = "Group User Role",
    AndroidFontScale = "Android Font Scale",
}

export const AnalyticsEventDefinitions: Record<AnalyticsEvents, AnalyticsEventDefinition> = {
    Accounts_AddLoanAccountDetails: {
        name: "Add Loan Account Details",
        properties: [],
    },
    Accounts_ClickedAddAccount: {
        name: "Clicked Add Account",
        properties: [],
    },
    Accounts_ClickedAddActivityButton: {
        name: "Clicked Add Activity button",
        properties: [],
    },
    Accounts_ClickedSectionHeader: {
        name: "Clicked Section Header",
        properties: [
            AnalyticsProperties.SectionHeaderEndState,
            AnalyticsProperties.SectionHeaderType,
            AnalyticsProperties.TransactionCount,
        ],
    },
    Accounts_ClickedTransactionSearchButton: {
        name: "Clicked Transaction Search Button",
        properties: [],
    },
    Accounts_CreatedAccount: {
        name: "Created Account",
        properties: [
            AnalyticsProperties.AccountType,
            AnalyticsProperties.ConnectionType,
            AnalyticsProperties.FinancialInstitution,
            AnalyticsProperties.ImportedAccountType,
            AnalyticsProperties.ZeroBalance,
        ],
    },
    Accounts_CreatedPayeeRenamingRule: {
        name: "Created Payee Renaming Rule",
        properties: [AnalyticsProperties.RenamingPath],
    },
    Accounts_FilteredAccount: {
        name: "Filtered Account",
        properties: [
            AnalyticsProperties.DateFilter,
            AnalyticsProperties.PendingTransactionsFilter,
            AnalyticsProperties.ReconciledTransactionFilter,
            AnalyticsProperties.ScheduledTransactionsFilter,
        ],
    },
    Accounts_FinishedReconciliation: {
        name: "Finished Reconciliation",
        properties: [
            AnalyticsProperties.AdjustmentAmount,
            AnalyticsProperties.AdjustmentTransactionCreated,
            AnalyticsProperties.Currency,
        ],
    },
    Accounts_SearchedAccount: {
        name: "Searched Account",
        properties: [
            AnalyticsProperties.SearchedAccount,
            AnalyticsProperties.SearchedApproved,
            AnalyticsProperties.SearchedCategory,
            AnalyticsProperties.SearchedCleared,
            AnalyticsProperties.SearchedDate,
            AnalyticsProperties.SearchedFlag,
            AnalyticsProperties.SearchedInflow,
            AnalyticsProperties.SearchedMemo,
            AnalyticsProperties.SearchedNeedsCategory,
            AnalyticsProperties.SearchedOutflow,
            AnalyticsProperties.SearchedPayee,
            AnalyticsProperties.SearchedReconciled,
            AnalyticsProperties.SearchedUnapproved,
            AnalyticsProperties.SearchedUncleared,
            AnalyticsProperties.SearchedUnreconciled,
        ],
    },
    Accounts_SelectedAccountConnectionType: {
        name: "Selected Account Connection Type",
        properties: [AnalyticsProperties.ConnectionType],
    },
    Accounts_StartedReconciliation: {
        name: "Started Reconciliation",
        properties: [AnalyticsProperties.StartedReconciliationMethod],
    },
    Accounts_UsedAccountsContextMenu: {
        name: "Used Accounts Context Menu",
        properties: [AnalyticsProperties.AccountsContextMenuSelection],
    },
    Budget_AdjustedAutoAssignAmount: {
        name: "Adjusted Auto-Assign Amount",
        properties: [],
    },
    Budget_AppliedAutoAssign: {
        name: "Applied Auto-Assign",
        properties: [],
    },
    Budget_AssignedMoney: {
        name: "Assigned Money",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.RtaState, AnalyticsProperties.UserJourneyState],
    },
    Budget_CanceledTargetSnooze: {
        name: "Canceled Target Snooze",
        properties: [AnalyticsProperties.TargetSnoozeLocation],
    },
    Budget_ClickedAssignMoreMoneyToCreditCardPayment: {
        name: "Clicked Assign More Money to Credit Card Payment",
        properties: [],
    },
    Budget_ClickedAutoAssign: {
        name: "Clicked Auto Assign",
        properties: [AnalyticsProperties.Location],
    },
    Budget_ClickedCategoryNotes: {
        name: "Clicked Category Notes",
        properties: [AnalyticsProperties.NoteInitialState],
    },
    Budget_ClickedCategoryRecentMoves: {
        name: "Clicked Category Recent Moves",
        properties: [],
    },
    Budget_ClickedCloseBudgetEditModeButton: {
        name: "Clicked Close Budget Edit Mode Button",
        properties: [],
    },
    Budget_ClickedCollapse_expandAllMenuItem: {
        name: "Clicked Collapse/Expand All Menu Item",
        properties: [],
    },
    Budget_ClickedCollapseAllGroups: {
        name: "Clicked Collapse All Groups",
        properties: [],
    },
    Budget_ClickedCoverCreditOverspending: {
        name: "Clicked Cover Credit Overspending",
        properties: [],
    },
    Budget_ClickedCreateDebtPayoffTarget: {
        name: "Clicked Create Debt Payoff Target",
        properties: [],
    },
    Budget_ClickedEnterBudgetEditModeButton: {
        name: "Clicked Enter Budget Edit Mode Button",
        properties: [],
    },
    Budget_ClickedExpandAllGroups: {
        name: "Clicked Expand All Groups",
        properties: [],
    },
    Budget_ClickedQuickBudget: {
        name: "Clicked Quick Budget",
        properties: [
            AnalyticsProperties.Auto_assignLocation,
            AnalyticsProperties.QuickBudgetCount,
            AnalyticsProperties.QuickBudgetMethod,
        ],
    },
    Budget_ClickedSpendingTrendShortcut: {
        name: "Clicked Spending Trend Shortcut",
        properties: [AnalyticsProperties.SpendingTrend],
    },
    Budget_ClickedViewUncategorizedCreditTransactions: {
        name: "Clicked View Uncategorized Credit Transactions",
        properties: [],
    },
    Budget_ClosedBudgetEditMode: {
        name: "Closed Budget Edit Mode",
        properties: [],
    },
    Budget_ClosedCoverOverspendingFlow: {
        name: "Closed Cover Overspending Flow",
        properties: [AnalyticsProperties.Result],
    },
    Budget_ClosedMoneyMoveFlow: {
        name: "Closed Money Move Flow",
        properties: [
            AnalyticsProperties.AmountOfCategoriesInMove,
            AnalyticsProperties.OverspendingCoverage,
            AnalyticsProperties.Result,
            AnalyticsProperties.UsedKeypad,
            AnalyticsProperties.UsedShortcut,
            AnalyticsProperties.UsedSliderWithASnapPoint,
            AnalyticsProperties.UsedSliderWithoutASnapPoint,
        ],
    },
    Budget_CompletedAddExpenses: {
        name: "Completed Add Expenses",
        properties: [],
    },
    Budget_CoveredOverspending: {
        name: "Covered Overspending",
        properties: [AnalyticsProperties.Coverage],
    },
    Budget_CreatedCategory: {
        name: "Created Category",
        properties: [AnalyticsProperties.Location],
    },
    Budget_CreatedCategoryGroup: {
        name: "Created Category Group",
        properties: [AnalyticsProperties.Location],
    },
    Budget_CreatedFocusedView: {
        name: "Created Focused View",
        properties: [],
    },
    Budget_CreatedGoal: {
        name: "Created Goal",
        properties: [
            AnalyticsProperties.GoalType,
            AnalyticsProperties.Location,
            AnalyticsProperties.NewTarget,
            AnalyticsProperties.SufficientIncome,
            AnalyticsProperties.UserJourneyState,
        ],
    },
    Budget_DeletedCategory: {
        name: "Deleted Category",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.UserJourneyState],
    },
    Budget_DeletedCategoryGroup: {
        name: "Deleted Category Group",
        properties: [],
    },
    Budget_DeletedFocusedView: {
        name: "Deleted Focused View",
        properties: [],
    },
    Budget_DisabledCoverOverspendingOutro: {
        name: "Disabled Cover Overspending Outro",
        properties: [],
    },
    Budget_EnteredAutoAssignPreview: {
        name: "Entered Auto-Assign Preview",
        properties: [AnalyticsProperties.UserJourneyState],
    },
    Budget_EnteredBudgetEditMode: {
        name: "Entered Budget Edit Mode",
        properties: [AnalyticsProperties.SufficientIncome],
    },
    Budget_EnteredExpectedIncome: {
        name: "Entered Expected Income",
        properties: [AnalyticsProperties.SufficientIncome],
    },
    Budget_ExpandedCreditCardPaymentDetail: {
        name: "Expanded Credit Card Payment Detail",
        properties: [AnalyticsProperties.Detail],
    },
    Budget_FilteredRecentMoves: {
        name: "Filtered Recent Moves",
        properties: [AnalyticsProperties.MovesTypeFilter],
    },
    Budget_FixedOverbudgeting: {
        name: "Fixed Overbudgeting",
        properties: [],
    },
    Budget_HidCategory: {
        name: "Hid Category",
        properties: [],
    },
    Budget_HidCategoryGroup: {
        name: "Hid Category Group",
        properties: [],
    },
    Budget_IncreasedMoneyInCreditCardPayment: {
        name: "Increased Money in Credit Card Payment",
        properties: [AnalyticsProperties.CreditCategoryStatus],
    },
    Budget_LeftAssignMode: {
        name: "Left Assign Mode",
        properties: [],
    },
    Budget_MovedMoney: {
        name: "Moved Money",
        properties: [AnalyticsProperties.FromCategoryType, AnalyticsProperties.ToCategoryType],
    },
    Budget_OpenedRecentMoves: {
        name: "Opened Recent Moves",
        properties: [],
    },
    Budget_Overbudgeted: {
        name: "Overbudgeted",
        properties: [],
    },
    Budget_ReorderedFocusedViews: {
        name: "Reordered Focused Views",
        properties: [],
    },
    Budget_SavedAssignments: {
        name: "Saved Assignments",
        properties: [
            AnalyticsProperties.PercentageOfCategoriesThatHaveTargets,
            AnalyticsProperties.PercentageOfCategoriesWhichWereAssignedTo,
            AnalyticsProperties.PercentageOfRemainingInRta,
        ],
    },
    Budget_SavedCreditCardPaymentTransaction: {
        name: "Saved Credit Card Payment Transaction",
        properties: [AnalyticsProperties.CreditCardBalanceAfterPayment, AnalyticsProperties.UnfundedPayment],
    },
    Budget_SelectedCategory: {
        name: "Selected Category",
        properties: [],
    },
    Budget_SelectedFocusedView: {
        name: "Selected Focused View",
        properties: [AnalyticsProperties.ViewType],
    },
    Budget_SetBudgetedAmount: {
        name: "Set Budgeted Amount",
        properties: [AnalyticsProperties.UsedMath],
    },
    Budget_SharedBudget: {
        name: "Shared Budget ",
        properties: [AnalyticsProperties.ShareMethod],
    },
    Budget_ShowedCategory: {
        name: "Showed Category",
        properties: [],
    },
    Budget_ShowedCategoryGroup: {
        name: "Showed Category Group",
        properties: [],
    },
    Budget_SnoozedTarget: {
        name: "Snoozed Target",
        properties: [AnalyticsProperties.TargetSnoozeLocation],
    },
    Budget_StartedCoverOverspendingFlow: {
        name: "Started Cover Overspending Flow",
        properties: [AnalyticsProperties.AmountOfOverspentCategories],
    },
    Budget_StartedMoneyMoveFlow: {
        name: "Started Money Move Flow",
        properties: [AnalyticsProperties.InitialDirection, AnalyticsProperties.State],
    },
    Budget_ToggledPrivacyMode: {
        name: "Toggled Privacy Mode",
        properties: [AnalyticsProperties.On_off],
    },
    Budget_ToggledProgressBars: {
        name: "Toggled Progress Bars",
        properties: [AnalyticsProperties.On_off],
    },
    Budget_TransferredBudgetOwnership: {
        name: "Transferred Budget Ownership",
        properties: [],
    },
    Budget_UnsharedBudget: {
        name: "Unshared Budget ",
        properties: [AnalyticsProperties.UnshareMethod],
    },
    Budget_UsedAssignKeypadAccessory: {
        name: "Used Assign Keypad Accessory",
        properties: [],
    },
    Budget_UsedBudgetContextMenu: {
        name: "Used Budget Context Menu",
        properties: [AnalyticsProperties.BudgetContextMenuSelection],
    },
    Budget_ViewedBudgetContextMenu: {
        name: "Viewed Budget Context Menu",
        properties: [],
    },
    Budget_ViewedCategoryDetails: {
        name: "Viewed Category Details",
        properties: [],
    },
    Budget_ViewedCategoryMoves: {
        name: "Viewed Category Moves",
        properties: [AnalyticsProperties.MonthDelta],
    },
    Budget_ViewedCreditCardPaymentInspector: {
        name: "Viewed Credit Card Payment Inspector",
        properties: [AnalyticsProperties.CreditCardPaymentStatus, AnalyticsProperties.MultipleWarnings],
    },
    Budget_ViewedNoExpensesAddedError: {
        name: "Viewed No Expenses Added Error",
        properties: [],
    },
    General_AskedToReviewApp: {
        name: "Asked to Review App",
        properties: [],
    },
    General_ChangedMemberName: {
        name: "Changed Member Name",
        properties: [],
    },
    General_ClickedPreNotificationMessage: {
        name: "Clicked Pre Notification Message",
        properties: [AnalyticsProperties.OptedIn],
    },
    General_ClickedShareBanner: {
        name: "Clicked Share Banner",
        properties: [],
    },
    General_CreatedNewBudget: {
        name: "Created New Budget",
        properties: [],
    },
    General_DeviceConnectivityPlaceholder: {
        name: "Device Connectivity Placeholder",
        properties: [AnalyticsProperties.DeviceConnectivity],
    },
    General_EnteredOverdraftState: {
        name: "Entered Overdraft State",
        properties: [],
    },
    General_LoggedIn: {
        name: "Logged In",
        properties: [AnalyticsProperties.LogInMethod],
    },
    General_LoggedOut: {
        name: "Logged Out",
        properties: [],
    },
    General_MadeFreshStart: {
        name: "Made Fresh Start",
        properties: [AnalyticsProperties.StopImportingTransactionsSelection],
    },
    General_OpenedPushNotification: {
        name: "Opened Push Notification",
        properties: [AnalyticsProperties.NotificationType],
    },
    General_SelectedPushNotificationConsent: {
        name: "Selected Push Notification Consent",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.OptedIn],
    },
    General_SharedYnab: {
        name: "Shared YNAB",
        properties: [AnalyticsProperties.CompletedShare, AnalyticsProperties.ShareLocation],
    },
    Help_ClickedHowYnabWorksButton: {
        name: "Clicked How YNAB Works Button",
        properties: [],
    },
    Help_ClickedShareVideo: {
        name: "Clicked Share Video",
        properties: [],
    },
    Help_ExpandedHelpArticleDetail: {
        name: "Expanded Help Article Detail",
        properties: [AnalyticsProperties.Article],
    },
    Help_SelectedPlaylistVideo: {
        name: "Selected Playlist Video",
        properties: [],
    },
    Help_StartedHowYnabWorksVideo: {
        name: "Started How YNAB Works Video",
        properties: [],
    },
    Help_SwipedQuickTip: {
        name: "Swiped Quick Tip",
        properties: [],
    },
    Help_ViewedHelpResource: {
        name: "Viewed Help Resource",
        properties: [AnalyticsProperties.Resource],
    },
    Import_ImportedDiTransactions: {
        name: "Imported DI Transactions",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.TransactionState],
    },
    Onboarding_AddedSuggestedCategory: {
        name: "Added Suggested Category",
        properties: [AnalyticsProperties.SuggestedCategoryName],
    },
    Onboarding_AdvancedCategoryWizardStep: {
        name: "Advanced Category Wizard Step",
        properties: [
            AnalyticsProperties.CategoryWizardResponse,
            AnalyticsProperties.ResponsePosition,
            AnalyticsProperties.WizardStep,
        ],
    },
    Onboarding_AssignedSavingsToExpenses: {
        name: "Assigned Savings to Expenses",
        properties: [],
    },
    Onboarding_CanceledAddAccountStep: {
        name: "Canceled Add Account Step",
        properties: [],
    },
    Onboarding_ClickedExpandableTextCard: {
        name: "Clicked Expandable Text Card",
        properties: [AnalyticsProperties.Location],
    },
    Onboarding_ClickedSuggestedCreditCardPaymentCategory: {
        name: "Clicked Suggested Credit Card Payment Category",
        properties: [],
    },
    Onboarding_ClosedAddAccountIntro: {
        name: "Closed Add Account Intro",
        properties: [],
    },
    Onboarding_ClosedIntro: {
        name: "Closed Intro",
        properties: [],
    },
    Onboarding_ClosedOutro: {
        name: "Closed Outro",
        properties: [],
    },
    Onboarding_CompletedAddAccountIntro: {
        name: "Completed Add Account Intro",
        properties: [],
    },
    Onboarding_CompletedAddAccountStep: {
        name: "Completed Add Account Step",
        properties: [],
    },
    Onboarding_CompletedBudgetStep: {
        name: "Completed Budget Step",
        properties: [],
    },
    Onboarding_CompletedOnboarding: {
        name: "Completed Onboarding",
        properties: [],
    },
    Onboarding_CreatedSavingsCategory: {
        name: "Created Savings Category",
        properties: [],
    },
    Onboarding_OpenedOutro: {
        name: "Opened Outro",
        properties: [AnalyticsProperties.OutroState],
    },
    Onboarding_RatedFeeling: {
        name: "Rated Feeling",
        properties: [AnalyticsProperties.FeelingRate],
    },
    Onboarding_RestartedCategoryWizard: {
        name: "Restarted Category Wizard",
        properties: [],
    },
    Onboarding_ReturnedToSettingTargetsOnboarding: {
        name: "Returned to Setting Targets Onboarding",
        properties: [],
    },
    Onboarding_SelectedSpendingRecipients: {
        name: "Selected Spending Recipients",
        properties: [AnalyticsProperties.SpendingRecipientType],
    },
    Onboarding_SkippedAddAccountStep: {
        name: "Skipped Add Account Step",
        properties: [],
    },
    Onboarding_SkippedSettingTargetsOnboarding: {
        name: "Skipped Setting Targets Onboarding",
        properties: [],
    },
    Onboarding_StartedAddAccountStep: {
        name: "Started Add Account Step",
        properties: [],
    },
    Onboarding_TriggeredCategoryWizardExistingAccountError: {
        name: "Triggered Category Wizard Existing Account Error",
        properties: [],
    },
    Onboarding_ViewedAddAccountsIntro: {
        name: "Viewed Add Accounts Intro",
        properties: [],
    },
    Onboarding_ViewedMobileLandingScreen: {
        name: "Viewed Mobile Landing Screen",
        properties: [],
    },
    PageView_ViewedAccount: {
        name: "Viewed Account",
        properties: [],
    },
    PageView_ViewedAccountList: {
        name: "Viewed Account List",
        properties: [],
    },
    PageView_ViewedAgeOfMoneyReport: {
        name: "Viewed Age of Money Report",
        properties: [],
    },
    PageView_ViewedAllAccounts: {
        name: "Viewed All Accounts",
        properties: [],
    },
    PageView_ViewedBudget: {
        name: "Viewed Budget",
        properties: [],
    },
    PageView_ViewedCategoryActivity: {
        name: "Viewed Category Activity",
        properties: [AnalyticsProperties.ViewedActivityMethod],
    },
    PageView_ViewedFamilySettings: {
        name: "Viewed Family Settings",
        properties: [],
    },
    PageView_ViewedHelp: {
        name: "Viewed Help",
        properties: [],
    },
    PageView_ViewedModalHelpArticle: {
        name: "Viewed Modal Help Article",
        properties: [AnalyticsProperties.Article],
    },
    PageView_ViewedNetWorthReport: {
        name: "Viewed Net Worth Report",
        properties: [],
    },
    PageView_ViewedReflectTab: {
        name: "Viewed Reflect Tab",
        properties: [],
    },
    PageView_ViewedReports: {
        name: "Viewed Reports",
        properties: [],
    },
    PageView_ViewedSettings: {
        name: "Viewed Settings",
        properties: [],
    },
    PageView_ViewedSpendingBreakdown: {
        name: "Viewed Spending Breakdown",
        properties: [],
    },
    Reports_FilteredReport: {
        name: "Filtered Report",
        properties: [
            AnalyticsProperties.AccountsFilter,
            AnalyticsProperties.CategoriesFilter,
            AnalyticsProperties.DateFilter,
            AnalyticsProperties.ReflectionPath,
        ],
    },
    SignUp_ClickedSignUp: {
        name: "Clicked Sign Up",
        properties: [],
    },
    SignUp_CompletedSignUp: {
        name: "Completed Sign Up",
        properties: [
            AnalyticsProperties.SignUpMethod,
            AnalyticsProperties.UtmCampaign,
            AnalyticsProperties.UtmContent,
            AnalyticsProperties.UtmMedium,
            AnalyticsProperties.UtmSource,
        ],
    },
    Subscription_CanceledSubscription: {
        name: "Canceled Subscription",
        properties: [AnalyticsProperties.ExitSurveyKey, AnalyticsProperties.SubscriptionPlan],
    },
    Subscription_FamilyInviteAccepted: {
        name: "Family Invite Accepted",
        properties: [AnalyticsProperties.AcceptedPlatform, AnalyticsProperties.AcceptedUserType],
    },
    Subscription_FamilyInviteExpired: {
        name: "Family Invite Expired",
        properties: [],
    },
    Subscription_FamilyMemberRemoved: {
        name: "Family Member Removed",
        properties: [AnalyticsProperties.RemovedBy],
    },
    Subscription_PurchasedSubscription: {
        name: "Purchased Subscription",
        properties: [
            AnalyticsProperties.SubscriptionExternalProductId,
            AnalyticsProperties.SubscriptionPlan,
            AnalyticsProperties.SubscriptionProvider,
            AnalyticsProperties.SubscriptionType,
            AnalyticsProperties.UtmCampaign,
            AnalyticsProperties.UtmContent,
            AnalyticsProperties.UtmMedium,
            AnalyticsProperties.UtmSource,
            AnalyticsProperties.YnabPlatform,
        ],
    },
    Subscription_RenewedSubscription: {
        name: "Renewed Subscription",
        properties: [AnalyticsProperties.SubscriptionPlan, AnalyticsProperties.SubscriptionProvider],
    },
    Subscription_SentFamilyInvite: {
        name: "Sent Family Invite",
        properties: [
            AnalyticsProperties.InvitedUserType,
            AnalyticsProperties.InviteType,
            AnalyticsProperties.NumberOfSharedBudgets,
        ],
    },
    Subscription_StartedTrial: {
        name: "Started Trial",
        properties: [AnalyticsProperties.SubscriptionPlan, AnalyticsProperties.SubscriptionProvider],
    },
    Subscription_ViewedExpirationMessage: {
        name: "Viewed Expiration Message",
        properties: [],
    },
    Subscription_ViewedPlanSelectionScreen: {
        name: "Viewed Plan Selection Screen",
        properties: [AnalyticsProperties.YnabPlatform],
    },
    Subscription_ViewedSubscriptionExpiredScreen: {
        name: "Viewed Subscription Expired Screen",
        properties: [],
    },
    Subscription_ViewedTrialExpiredScreen: {
        name: "Viewed Trial Expired Screen",
        properties: [],
    },
    Transaction_ApprovedTransaction: {
        name: "Approved Transaction",
        properties: [AnalyticsProperties.ApprovedTransactionMethod],
    },
    Transaction_ClickedAddTransaction: {
        name: "Clicked Add Transaction",
        properties: [],
    },
    Transaction_ClickedCategorySearchResult: {
        name: "Clicked Category Search Result",
        properties: [AnalyticsProperties.Location],
    },
    Transaction_ClickedCategorySuggestion: {
        name: "Clicked Category Suggestion",
        properties: [AnalyticsProperties.Position],
    },
    Transaction_ClickedSplitTransaction: {
        name: "Clicked Split Transaction",
        properties: [AnalyticsProperties.Location],
    },
    Transaction_ClickedSuggestionsTooltip: {
        name: "Clicked Suggestions Tooltip",
        properties: [],
    },
    Transaction_CreatedTransaction: {
        name: "Created Transaction",
        properties: [AnalyticsProperties.AccountType, AnalyticsProperties.TransactionValue],
    },
    Transaction_DeletedTransaction: {
        name: "Deleted Transaction",
        properties: [AnalyticsProperties.DeletedTransactionMethod],
    },
    Transaction_DuplicatedTransaction: {
        name: "Duplicated Transaction",
        properties: [AnalyticsProperties.DuplicatedTransactionMethod],
    },
    Transaction_EditedTransaction: {
        name: "Edited Transaction",
        properties: [],
    },
    Transaction_MatchedTransaction: {
        name: "Matched Transaction",
        properties: [AnalyticsProperties.MatchedTransactionMethod],
    },
    Transaction_MovedAccount: {
        name: "Moved Account",
        properties: [],
    },
    Transaction_ProcessedTransaction: {
        name: "Processed Transaction",
        properties: [AnalyticsProperties.ProcessAction],
    },
    Transaction_RejectedTransaction: {
        name: "Rejected Transaction",
        properties: [AnalyticsProperties.RejectedTransactionMethod],
    },
    Transaction_RemovePayeeLocation: {
        name: "Remove payee location",
        properties: [],
    },
    Transaction_SavedTransaction: {
        name: "Saved Transaction",
        properties: [],
    },
    Transaction_SearchedCategory: {
        name: "Searched Category",
        properties: [AnalyticsProperties.Location],
    },
    Transaction_SetAccount: {
        name: "Set Account",
        properties: [AnalyticsProperties.SetAccountMethod],
    },
    Transaction_SetCategory: {
        name: "Set Category",
        properties: [AnalyticsProperties.Position, AnalyticsProperties.SetCategorySource],
    },
    Transaction_SetClearedStatus: {
        name: "Set Cleared Status",
        properties: [AnalyticsProperties.ClearedStatus, AnalyticsProperties.SetClearedStatusMethod],
    },
    Transaction_SetDate: {
        name: "Set Date",
        properties: [AnalyticsProperties.Scheduled],
    },
    Transaction_SetFlag: {
        name: "Set Flag",
        properties: [AnalyticsProperties.CustomFlagName, AnalyticsProperties.SetFlagMethod],
    },
    Transaction_SetMemo: {
        name: "Set Memo",
        properties: [],
    },
    Transaction_SetPayee: {
        name: "Set Payee",
        properties: [],
    },
    Transaction_SetRepeat: {
        name: "Set Repeat",
        properties: [AnalyticsProperties.RepeatValue],
    },
    Transaction_SetTransactionAmount: {
        name: "Set Transaction Amount",
        properties: [AnalyticsProperties.TransactionValue],
    },
    Transaction_UnmatchedTransaction: {
        name: "Unmatched Transaction",
        properties: [AnalyticsProperties.UnmatchedTransactionMethod],
    },
    Transaction_UsedCalculator: {
        name: "Used Calculator",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.Operation],
    },
    Transaction_UsedEnterNow: {
        name: "Used Enter Now",
        properties: [
            AnalyticsProperties.TransactionCount,
            AnalyticsProperties.TransactionsType,
            AnalyticsProperties.UsedEnterNowMethod,
        ],
    },
    Transaction_UsedTransactionContextMenu: {
        name: "Used Transaction Context Menu",
        properties: [AnalyticsProperties.TransactionContextMenuSelection],
    },
    Transaction_ViewedAllCategories: {
        name: "Viewed All Categories",
        properties: [],
    },
    Accounts_AcceptedFallbackProvider: {
        name: "Accepted Fallback Provider",
        properties: [],
    },
    Accounts_CheckedFallbackProviderInstitutionEligibility: {
        name: "Checked Fallback Provider Institution Eligibility",
        properties: [
            AnalyticsProperties.DirectImportProviderError,
            AnalyticsProperties.FinancialInstitution,
            AnalyticsProperties.InstitutionCount,
            AnalyticsProperties.SearchTerm,
        ],
    },
    Accounts_ClickedAddConnection: {
        name: "Clicked Add Connection",
        properties: [],
    },
    Accounts_ClickedDynamicDefaultProviderInstitutionButton: {
        name: "Clicked Dynamic Default Provider Institution Button",
        properties: [AnalyticsProperties.FinancialInstitution],
    },
    Accounts_ClickedDynamicDefaultProviderSearchButton: {
        name: "Clicked Dynamic Default Provider Search Button",
        properties: [],
    },
    Accounts_ClickedExistingConnection: {
        name: "Clicked Existing Connection",
        properties: [],
    },
    Accounts_ClickedViewAllTransactions: {
        name: "Clicked View All Transactions",
        properties: [AnalyticsProperties.ProcessingState],
    },
    Accounts_ClickedViewMyBudget: {
        name: "Clicked View My Budget",
        properties: [],
    },
    Accounts_ClickedViewOverspentCategories: {
        name: "Clicked View Overspent Categories",
        properties: [AnalyticsProperties.ProcessingState],
    },
    Accounts_ClickedViewRemainingTransactions: {
        name: "Clicked View Remaining Transactions",
        properties: [AnalyticsProperties.ProcessingState],
    },
    Accounts_ClosedAccount: {
        name: "Closed Account",
        properties: [AnalyticsProperties.AccountType],
    },
    Accounts_CombinedPayees: {
        name: "Combined Payees",
        properties: [AnalyticsProperties.Count],
    },
    Accounts_CopiedPaydownAnalysisAmountToBudget: {
        name: "Copied Paydown Analysis Amount to Budget",
        properties: [AnalyticsProperties.PaydownType],
    },
    Accounts_CreatedPaydownTarget: {
        name: "Created Paydown Target",
        properties: [AnalyticsProperties.GoalType],
    },
    Accounts_DeletedBankAccount: {
        name: "Deleted Bank Account",
        properties: [AnalyticsProperties.AccountType],
    },
    Accounts_DeletedLoanActivity: {
        name: "Deleted Loan Activity",
        properties: [AnalyticsProperties.ActivityType],
    },
    Accounts_EditedLoanActivity: {
        name: "Edited Loan Activity",
        properties: [AnalyticsProperties.ActivityType, AnalyticsProperties.Status],
    },
    Accounts_EncounteredDirectImportError: {
        name: "Encountered Direct Import Error",
        properties: [AnalyticsProperties.DirectImportProvider, AnalyticsProperties.DirectImportProviderError],
    },
    Accounts_EnteredAccountDetails: {
        name: "Entered Account Details",
        properties: [AnalyticsProperties.AccountType, AnalyticsProperties.ZeroBalance],
    },
    Accounts_HidRunningBalance: {
        name: "Hid Running Balance",
        properties: [AnalyticsProperties.AccountType, AnalyticsProperties.ConnectionType],
    },
    Accounts_PairedAccountWithExistingCategory: {
        name: "Paired Account with Existing Category",
        properties: [AnalyticsProperties.Location],
    },
    Accounts_PairedAccountWithNewCategory: {
        name: "Paired Account with New Category",
        properties: [AnalyticsProperties.Location],
    },
    Accounts_RecordedLoanActivity: {
        name: "Recorded Loan Activity",
        properties: [AnalyticsProperties.ActivityType],
    },
    Accounts_SelectedExistingBalancePaydownType: {
        name: "Selected Existing Balance Paydown Type",
        properties: [AnalyticsProperties.PaydownType],
    },
    Accounts_SelectedInstitution: {
        name: "Selected Institution",
        properties: [AnalyticsProperties.FinancialInstitution, AnalyticsProperties.Location],
    },
    Accounts_SelectedRegion: {
        name: "Selected Region",
        properties: [AnalyticsProperties.Region],
    },
    Accounts_ShowedRunningBalance: {
        name: "Showed Running Balance",
        properties: [AnalyticsProperties.AccountType, AnalyticsProperties.ConnectionType],
    },
    Accounts_SkippedCategoryPairing: {
        name: "Skipped Category Pairing",
        properties: [AnalyticsProperties.AccountType],
    },
    Accounts_StartedDebtAccountMigration: {
        name: "Started Debt Account Migration",
        properties: [],
    },
    Accounts_StartedDebtStartingBalanceErrorFix: {
        name: "Started Debt Starting Balance Error Fix",
        properties: [],
    },
    Accounts_UnpairedAccountFromCategory: {
        name: "Unpaired Account from Category",
        properties: [AnalyticsProperties.AccountType],
    },
    Accounts_UpdateBalance: {
        name: "Update Balance",
        properties: [
            AnalyticsProperties.AdjustmentAmount,
            AnalyticsProperties.CorrectedMatch,
            AnalyticsProperties.Currency,
            AnalyticsProperties.Escrow,
            AnalyticsProperties.Interest,
        ],
    },
    Accounts_UsedPaydownAnalysis: {
        name: "Used Paydown Analysis",
        properties: [AnalyticsProperties.SimulatorInputType],
    },
    Budget_ClickedCollapse_expandSidebar: {
        name: "Clicked Collapse/Expand Sidebar",
        properties: [AnalyticsProperties.Expand_collapsedSidebarMethod],
    },
    Budget_ClickedMonthNotes: {
        name: "Clicked Month Notes",
        properties: [AnalyticsProperties.NoteInitialState],
    },
    Budget_ClickedPairCategoryWithLoan: {
        name: "Clicked Pair Category with Loan",
        properties: [],
    },
    Budget_ClickedQuickBudgetFirstTime: {
        name: "Clicked Quick Budget First Time",
        properties: [AnalyticsProperties.QuickBudgetCount, AnalyticsProperties.QuickBudgetMethod],
    },
    Budget_EditedDebtTarget: {
        name: "Edited Debt Target",
        properties: [AnalyticsProperties.DebtTargetInputType, AnalyticsProperties.PairedCategory],
    },
    Budget_FinishedMoveMoneyFromTbb: {
        name: "Finished Move Money from TBB",
        properties: [AnalyticsProperties.FromTbbOrToTbb],
    },
    Budget_ImportedCategoryTemplate: {
        name: "Imported Category Template",
        properties: [AnalyticsProperties.CategoryTemplate, AnalyticsProperties.ImportedSelectCategories],
    },
    Budget_PairedCategoryWithLoan: {
        name: "Paired Category with Loan",
        properties: [],
    },
    Budget_SelectedCategoryGroup: {
        name: "Selected Category Group",
        properties: [],
    },
    Budget_StartedCreateTarget: {
        name: "Started Create Target",
        properties: [AnalyticsProperties.UserJourneyState],
    },
    Budget_StartedMoveMoneyFromTbb: {
        name: "Started Move Money from TBB",
        properties: [AnalyticsProperties.TbbAmount],
    },
    Budget_StartedRecordPaymentFromInspector: {
        name: "Started Record Payment from Inspector",
        properties: [],
    },
    Budget_ViewedKeyboardShortcuts: {
        name: "Viewed Keyboard Shortcuts",
        properties: [AnalyticsProperties.ViewedKeyboardShortcutsMethod],
    },
    Budget_ViewedToBeBudgetedBreakdown: {
        name: "Viewed To be Budgeted Breakdown",
        properties: [],
    },
    General_AgedMoneyFirstTime: {
        name: "Aged Money First Time",
        properties: [],
    },
    General_BlockedTracking: {
        name: "Blocked Tracking",
        properties: [],
    },
    General_ClickedCopyGroupInvite: {
        name: "Clicked Copy Group Invite",
        properties: [],
    },
    General_ClickedMultiSelectCheckbox: {
        name: "Clicked Multi-Select Checkbox",
        properties: [AnalyticsProperties.Action, AnalyticsProperties.Location],
    },
    General_ClickedReferralProgramButton: {
        name: "Clicked Referral Program Button",
        properties: [AnalyticsProperties.Location],
    },
    General_CreatedNewGroupInvite: {
        name: "Created New Group Invite",
        properties: [],
    },
    General_ExportedBudget: {
        name: "Exported Budget",
        properties: [],
    },
    General_ExportedReport: {
        name: "Exported Report",
        properties: [AnalyticsProperties.ReportType],
    },
    General_ExportedTransactions: {
        name: "Exported Transactions",
        properties: [AnalyticsProperties.Location],
    },
    General_OpenedBudget: {
        name: "Opened Budget",
        properties: [],
    },
    General_RampedFeatureFlag: {
        name: "Ramped Feature Flag",
        properties: [AnalyticsProperties.FeatureFlag],
    },
    General_RemovedGroupMember: {
        name: "Removed Group Member",
        properties: [],
    },
    General_SignedUpForWaitingList: {
        name: "Signed Up for Waiting List",
        properties: [AnalyticsProperties.WaitingList],
    },
    General_UpdatedUserProperties: {
        name: "Updated User Properties",
        properties: [],
    },
    General_UsedKeyboardShortcut: {
        name: "Used Keyboard Shortcut",
        properties: [AnalyticsProperties.KeyboardShortcut],
    },
    Help_ViewedGroupResources: {
        name: "Viewed Group Resources",
        properties: [AnalyticsProperties.Resource],
    },
    Import_ClickedAddAppleWalletConnection: {
        name: " Clicked Add Apple Wallet Connection",
        properties: [],
    },
    Import_ClosedPlaidWidget: {
        name: "Closed Plaid Widget",
        properties: [
            AnalyticsProperties.PlaidAccountsLinkedCount,
            AnalyticsProperties.PlaidChosePreselectedInstitution,
            AnalyticsProperties.PlaidExitReasonSummary,
            AnalyticsProperties.PlaidPhoneField,
            AnalyticsProperties.PlaidSavedAccountsScreen,
            AnalyticsProperties.PlaidSavedInstitutionsScreen,
            AnalyticsProperties.PlaidWidgetOpenDuration,
        ],
    },
    Import_FinishedConnectionFlow: {
        name: "Finished Connection Flow",
        properties: [AnalyticsProperties.ConnectionType, AnalyticsProperties.Outcome],
    },
    Import_ImportedFbiTransactions: {
        name: "Imported FBI Transactions",
        properties: [AnalyticsProperties.FbiError, AnalyticsProperties.FileType, AnalyticsProperties.Location],
    },
    Import_ImportedPendingTransactions: {
        name: "Imported Pending Transactions",
        properties: [],
    },
    Import_ViewedPlaidWidget: {
        name: "Viewed Plaid Widget",
        properties: [],
    },
    Onboarding_AdvancedMintImporterStep: {
        name: "Advanced Mint Importer Step",
        properties: [
            AnalyticsProperties.ImporterStep,
            AnalyticsProperties.MigrationPath,
            AnalyticsProperties.OnboardingPath,
        ],
    },
    Onboarding_ClickedMigrateFrom: {
        name: "Clicked Migrate From",
        properties: [AnalyticsProperties.MigrationSource],
    },
    Onboarding_ClickedWizardSignUp: {
        name: "Clicked Wizard Sign Up",
        properties: [],
    },
    Onboarding_CompletedCreateGoalStep: {
        name: "Completed Create Goal Step",
        properties: [],
    },
    Onboarding_CompletedMoveMoneyStep: {
        name: "Completed Move Money Step",
        properties: [],
    },
    Onboarding_CompletedScheduleTransactionStep: {
        name: "Completed Schedule Transaction Step",
        properties: [],
    },
    Onboarding_DoneEstimatingExpenses: {
        name: "Done Estimating Expenses",
        properties: [],
    },
    Onboarding_LaunchedCategoryWizard: {
        name: "Launched Category Wizard",
        properties: [],
    },
    Onboarding_PreviewedWizardResults: {
        name: "Previewed Wizard Results",
        properties: [],
    },
    Onboarding_SelectedBudgetIntention: {
        name: "Selected Budget Intention",
        properties: [AnalyticsProperties.BudgetIntention],
    },
    Onboarding_SelectedIncomeSituation: {
        name: "Selected Income Situation",
        properties: [AnalyticsProperties.BudgetSituation_Income],
    },
    Onboarding_SelectedUsersSituation: {
        name: "Selected Users Situation",
        properties: [AnalyticsProperties.BudgetSituation_Users],
    },
    Onboarding_SkippedAddingOnBudgetAccount: {
        name: "Skipped Adding On-Budget Account",
        properties: [],
    },
    Onboarding_SkippedIntentionStep: {
        name: "Skipped Intention Step",
        properties: [],
    },
    Onboarding_SkippedOnboarding: {
        name: "Skipped Onboarding",
        properties: [],
    },
    Onboarding_SkippedSituationStep: {
        name: "Skipped Situation Step",
        properties: [],
    },
    Onboarding_StartedAssignMoneyStep: {
        name: "Started Assign Money Step",
        properties: [],
    },
    Onboarding_StartedWebFtueOnboarding: {
        name: "Started Web FTUE Onboarding",
        properties: [],
    },
    Onboarding_StartedWebLegacyOnboarding: {
        name: "Started Web Legacy Onboarding",
        properties: [],
    },
    PageView_ViewedGroupDashboard: {
        name: "Viewed Group Dashboard",
        properties: [],
    },
    PageView_ViewedGroupMembers: {
        name: "Viewed Group Members",
        properties: [],
    },
    PageView_ViewedGroupSubscription: {
        name: "Viewed Group Subscription",
        properties: [],
    },
    PageView_ViewedIncomeVExpenseReport: {
        name: "Viewed Income v Expense Report",
        properties: [],
    },
    PageView_ViewedInvoices: {
        name: "Viewed Invoices",
        properties: [],
    },
    PageView_ViewedManagePayees: {
        name: "Viewed Manage Payees",
        properties: [AnalyticsProperties.Location],
    },
    PageView_ViewedReconciliationPopover: {
        name: "Viewed Reconciliation Popover",
        properties: [
            AnalyticsProperties.DirectImportCurrentBalanceMatch,
            AnalyticsProperties.FinancialInstitution,
            AnalyticsProperties.ReconciliationStep,
        ],
    },
    PageView_ViewedReferralProgram: {
        name: "Viewed Referral Program",
        properties: [],
    },
    PageView_ViewedSpendingReport: {
        name: "Viewed Spending Report",
        properties: [],
    },
    PageView_ViewedSubscribe: {
        name: "Viewed Subscribe",
        properties: [],
    },
    SignUp_CreatedUser: {
        name: "Created User",
        properties: [AnalyticsProperties.SignUpMethod, AnalyticsProperties.SignUpSource],
    },
    SignUp_SignedMsa: {
        name: "Signed MSA",
        properties: [],
    },
    SignUp_VerifiedEmailAddress: {
        name: "Verified Email Address",
        properties: [],
    },
    SignUp_ViewedSignUpWithBudgetTemplate: {
        name: "Viewed Sign Up with Budget Template",
        properties: [],
    },
    Social_AcceptedReferralInvite: {
        name: "Accepted Referral Invite",
        properties: [],
    },
    Social_ClickedReferYnab: {
        name: "Clicked Refer YNAB",
        properties: [],
    },
    Social_SentReferralInvite: {
        name: "Sent Referral Invite",
        properties: [AnalyticsProperties.ReferralMethod],
    },
    Subscription_AddedBillingLocation: {
        name: "Added Billing Location",
        properties: [],
    },
    Subscription_ChangedGroupAttribute: {
        name: "Changed Group Attribute",
        properties: [
            AnalyticsProperties.Attribute,
            AnalyticsProperties.GroupId,
            AnalyticsProperties.GroupName,
            AnalyticsProperties.GroupSeatsLimit,
            AnalyticsProperties.GroupSeatsUsed,
        ],
    },
    Subscription_ChangedSubscriptionStatus: {
        name: "Changed Subscription Status",
        properties: [AnalyticsProperties.SubscriptionStatus],
    },
    Subscription_ClickedSubscribeNow: {
        name: "Clicked Subscribe Now",
        properties: [],
    },
    Subscription_CreatedGroup: {
        name: "Created Group",
        properties: [AnalyticsProperties.GroupId, AnalyticsProperties.GroupName],
    },
    Subscription_DeletedAccount: {
        name: "Deleted Account",
        properties: [],
    },
    Subscription_DeletedGroup: {
        name: "Deleted Group",
        properties: [AnalyticsProperties.GroupId, AnalyticsProperties.GroupName],
    },
    Subscription_ExtendedTrial: {
        name: "Extended Trial",
        properties: [AnalyticsProperties.ExtendedBy, AnalyticsProperties.TrialExtensionDuration],
    },
    Subscription_ExtendedTrialByCustomer: {
        name: "Extended Trial By Customer",
        properties: [],
    },
    Subscription_ExtendedTrialBySupport: {
        name: "Extended Trial By Support",
        properties: [],
    },
    Subscription_GiftedSubscription: {
        name: "Gifted Subscription",
        properties: [],
    },
    Subscription_JoinedGroup: {
        name: "Joined Group",
        properties: [
            AnalyticsProperties.GroupId,
            AnalyticsProperties.GroupName,
            AnalyticsProperties.GroupSeatsLimit,
            AnalyticsProperties.GroupSeatsUsed,
            AnalyticsProperties.GroupUserId,
        ],
    },
    Subscription_LapsedAppleSubscription: {
        name: "Lapsed Apple Subscription",
        properties: [AnalyticsProperties.ExpirationIntent],
    },
    Subscription_LeftGroup: {
        name: "Left Group",
        properties: [
            AnalyticsProperties.GroupId,
            AnalyticsProperties.GroupName,
            AnalyticsProperties.GroupSeatsLimit,
            AnalyticsProperties.GroupSeatsUsed,
            AnalyticsProperties.GroupUserId,
        ],
    },
    Subscription_PurchasedFutureSubscription: {
        name: "Purchased Future Subscription",
        properties: [AnalyticsProperties.SubscriptionPlan],
    },
    Subscription_SelectedCancellationReason: {
        name: "Selected Cancellation Reason",
        properties: [AnalyticsProperties.ExitSurveyOption],
    },
    Subscription_SelectedPayAsYouGo: {
        name: "Selected Pay as You Go",
        properties: [],
    },
    Subscription_SelectedPayUpFront: {
        name: "Selected Pay Up Front",
        properties: [],
    },
    Subscription_UpdatedBillingInfo: {
        name: "Updated Billing Info",
        properties: [],
    },
    Subscription_UpdatedGroupBillingInfo: {
        name: "Updated Group Billing Info",
        properties: [AnalyticsProperties.GroupId, AnalyticsProperties.GroupName],
    },
    Subscription_UpdatedGroupUser: {
        name: "Updated Group User",
        properties: [AnalyticsProperties.GroupId, AnalyticsProperties.GroupName, AnalyticsProperties.GroupUserId],
    },
    Support_ChangedPaymentGateway: {
        name: "Changed Payment Gateway",
        properties: [AnalyticsProperties.NewPaymentGateway],
    },
    Support_ChangedSupportAccess: {
        name: "Changed Support Access",
        properties: [AnalyticsProperties.Permission],
    },
    Support_ClickedChatWidget: {
        name: "Clicked Chat Widget",
        properties: [
            AnalyticsProperties.OpenConversationCount,
            AnalyticsProperties.OpenConversationsIds,
            AnalyticsProperties.PageName,
            AnalyticsProperties.WidgetReason,
            AnalyticsProperties.WidgetSelected,
        ],
    },
    Support_ContactedSupport: {
        name: "Contacted Support",
        properties: [AnalyticsProperties.ConversationSource, AnalyticsProperties.SupportMethod],
    },
    Support_ReceivedCaResponse: {
        name: "Received CA Response",
        properties: [
            AnalyticsProperties.ConversationCategory,
            AnalyticsProperties.ConversationId,
            AnalyticsProperties.ConversationSource,
        ],
    },
    Support_ReceivedSupportResponse: {
        name: "Received Support Response",
        properties: [
            AnalyticsProperties.ConversationCategory,
            AnalyticsProperties.ConversationId,
            AnalyticsProperties.ConversationSource,
        ],
    },
    Transaction_ClickedBigBoxLink: {
        name: "Clicked Big Box Link",
        properties: [AnalyticsProperties.DestinationUrl],
    },
    Transaction_CustomizedFlagNames: {
        name: "Customized Flag Names",
        properties: [AnalyticsProperties.FlagsWithCustomNames],
    },
    Transaction_FailedSubscriptionAction: {
        name: "Failed Subscription Action",
        properties: [
            AnalyticsProperties.TransactionFailedGateway,
            AnalyticsProperties.TransactionFailedReason,
            AnalyticsProperties.TransactionFailedSource,
        ],
    },
    Budget_DismissedTooltip: {
        name: "Dismissed Tooltip",
        properties: [],
    },
    Help_ClickedPreviousMonthOverspendingHelpBanner: {
        name: "Clicked Previous Month Overspending Help Banner",
        properties: [AnalyticsProperties.OverspendingType],
    },
    PageView_ViewedPreviousMonthOverspendingHelp: {
        name: "Viewed Previous Month Overspending Help",
        properties: [AnalyticsProperties.OverspendingType],
    },
    PageView_ViewedPreviousMonthOverspendingHelpBanner: {
        name: "Viewed Previous Month Overspending Help Banner",
        properties: [AnalyticsProperties.Location, AnalyticsProperties.OverspendingType],
    },
    Subscription_SkippedPlanSelection: {
        name: "Skipped Plan Selection",
        properties: [],
    },
    Subscription_ViewedIneligibleForTrialScreen: {
        name: "Viewed Ineligible for Trial Screen",
        properties: [AnalyticsProperties.YnabPlatform],
    },
    Subscription_ViewedSubscriptionChangeScreen: {
        name: "Viewed Subscription Change Screen",
        properties: [AnalyticsProperties.YnabPlatform],
    },
};
